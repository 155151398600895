<template>
  <SignalR ref="signalR" v-if="false" />
  <CheckInternetConnection />
  <router-view />
  <ButtonModal
    :button="triggeredButton"
    :isLoading="triggeredButtonRequestIsLoading"
    :isOk="triggeredButtonResponse.isOk"
    :message="triggeredButtonResponse.message"
    :notificationType="triggeredButtonResponse.notificationType"
    :businessRuleResponses="triggeredButtonResponse.businessRuleResponses"
    :buttonValidations="triggeredButtonResponse.validations"
  />
  <ButtonApproveModal :button="triggeredButton" @approved="buttonApproved" />
  <EditCellErrorModal
    v-if="$route.meta.isDetailPage"
    id="detailPageEditCells"
    :inlineErrors="editModeErrors"
    :inlineWarnings="editModeWarnings"
    :businessRuleResponse="editModeBusinessRuleResponse"
  />
  <SetPagesJsonViewerModal :json="json" />
  <DropdownApproveModal v-if="$route.meta.isListPage" :dropdown="dropdown" />
</template>
<script>
import SignalR from "@/components/signalR/signalR.vue";

import CheckInternetConnection from "@/components/custom/connection-checker/Checker";
import DropdownApproveModal from "@/components/custom/dropdown/DropdownApproveModal";

import { nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/themes";

import $ from "jquery";
import intlTelInput from "intl-tel-input";
import Inputmask from "inputmask";
import { createToast } from "mosha-vue-toastify";

import SetPagesJsonViewerModal from "@/components/custom/json-viewer/SetPagesJsonViewerModal.vue";

import ButtonModal from "@/components/custom/button/ButtonModal.vue";
import ButtonApproveModal from "@/components/custom/button/ButtonApproveModal.vue";
import EditCellErrorModal from "@/components/custom/set-pages/InlineErrorModal.vue";

import trMessages from "devextreme/localization/messages/tr.json";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import {
  showModal,
  hideModal,
  removeModalBackdrops,
  removeModals,
  removeTooltips,
} from "@/core/helpers/dom";

import dxDateBox from "devextreme/ui/date_box";
import leafletJS from "leaflet";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "App",
  components: {
    SignalR,
    ButtonModal,
    ButtonApproveModal,
    EditCellErrorModal,
    SetPagesJsonViewerModal,
    DropdownApproveModal,
    CheckInternetConnection,
  },
  //To use data properties in child pages, You can use it from this.$root.PROPERTY_NAME
  data() {
    return {
      json: null,
      setScriptBodyKey: null,
      setXrmInputSpinnerGifSrc:
        "background:url(/extras/media/spinner/spinner.gif) no-repeat center center !important",
      invalidClasses: [
        ".file-invalid",
        ".html-editor-invalid",
        ".select2-invalid",
        ".intl-input-invalid",
      ],

      dxGridTemplateTypes: [
        { id: 0, text: "HeaderCell" },
        { id: 1, text: "Cell" },
        { id: 2, text: "EditCell" },
      ],
      fieldTypes: {
        ajax: {
          lookup: {
            url: "rws-LookupFieldValues",
            parameters: ["coId", "fieldPublicId", "controllingRecordId"],
          },
          organizationalunit: {
            url: "rws-OrganizationalUnitItems",
            parameters: [
              "publicId",
              "name",
              "filterType",
              "groupIds",
              "includeItself",
              "depth",
              "isAddCurrentKeys",
            ],
          },
          select: {
            url: "rws-FieldItems",
            parameters: ["id"],
          },
          dependentSelect: {
            url: "rws-DependencyItems",
            parameters: ["controllingRecordId", "parentId"],
          },
          predefined: {
            url: "rws-PredefinedItems",
            parameters: ["parentId"],
          },
          dependentPredefined: {
            url: "rws-PredefinedDependencyItems",
            parameters: [
              "id",
              "predefinedDependencyPublicId",
              "controllingRecordId",
            ],
          },
        },
        settings: {
          spatial: {
            zoom: 10,
            minZoom: 3,
            maxZoom: 15,
            zoomAnimation: true,
            mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          },
        },
        text: ["Text", "FormulaString", "Url", "AutoId", "Email"],
        checkbox: ["Checkbox"],
        number: [
          "Percent",
          "Number",
          "DoublePrecision",
          "LongInteger",
          "FormulaNumber",
          "Rollup",
        ],
        date: ["Date", "FormulaDate"],
        datetime: ["DateTime", "FormulaDateTime"],
        time: ["Time"],
        select: [
          "SelectList",
          "Lookup",
          "OrganizationalUnit",
          "FormulaOrganizationalUnit",
          "Predefined",
        ],
        textarea: ["TextArea", "FormulaTextArea"],
        file: ["File", "Image"],
        phone: ["Phone"],
        html: ["Html"], //TODO: inline?
        spatial: ["Spatial", "FormulaSpatial"],
        all: [
          "Checkbox",
          "Text",
          "FormulaString",
          "Url",
          "AutoId",
          "Email",
          "Percent",
          "Number",
          "DoublePrecision",
          "LongInteger",
          "FormulaNumber",
          "Rollup",
          "Date",
          "FormulaDate",
          "DateTime",
          "FormulaDateTime",
          "Time",
          "TextArea",
          "FormulaTextArea",
          "SelectList",
          "Lookup",
          "OrganizationalUnit",
          "FormulaOrganizationalUnit",
          "Predefined",
          "File",
          "Image",
          "Phone",
          "Spatial",
          "FormulaSpatial",
          "Html", //string textbox for inline
        ],
      },

      select2DropdownParents: [
        {
          id: "calendar",
          parentId: "#fullCalendarEventModal .modal-content:first",
        },
        { id: "tree", parentId: "#treeNewRecordModal .modal-content:first" },
      ],

      //for detail page edit cell
      editModeErrors: [],
      editModeWarnings: [],
      editModeBusinessRuleResponse: {},

      triggeredButton: {},
      triggeredButtonRequestIsLoading: false,
      triggeredButtonResponse: {
        isOk: false,
        message: null,
        businessRuleResponses: [],
        validations: [],
      },

      dropdown: null,

      /**
       * Bootswatch Helper
       * @param folder: src\assets\CREATED_FOLDER_URL !!!
       * @param file Path: ${bootswatch.folder}/${file}
       * @param image Path: ${bootswatch.folder}/${items !!! CurrentThemeId}/${bootswatch.image}
       * @param defaultTheme items !!! CurrentThemeId
       * @param items Bootswatch Themes or Custom Themes
       */
      bootswatch: {
        title: "bootswatchThemes",
        folder: process.env.VUE_APP_THEMES_FOLDER_URL,
        file: "bootstrap.min.css",
        defaultTheme: process.env.VUE_APP_DEFAULT_BOOTSWATCH_THEME,
        items: [
          { id: "cerulean", name: "Cerulean", description: null },
          { id: "cosmo", name: "Cosmo", description: null },
          { id: "cyborg", name: "Cyborg", description: null },
          { id: "darkly", name: "Darkly", description: null },
          { id: "flatly", name: "Flatly", description: null },
          { id: "journal", name: "Journal", description: null },
          { id: "litera", name: "Litera", description: null },
          { id: "lumen", name: "Lumen", description: null },
          { id: "lux", name: "Lux", description: null },
          { id: "materia", name: "Meteria", description: null },
          { id: "minty", name: "minty", description: null },
          { id: "morph", name: "Morph", description: null },
          { id: "pulse", name: "Pulse", description: null },
          { id: "quartz", name: "Quartz", description: null },
          {
            id: "sandstone",
            name: "Sandstone",
            description: null,
          },
          { id: "simplex", name: "Simplex", description: null },
          { id: "sketchy", name: "Sketchy", description: null },
          { id: "slate", name: "Slate", description: null },
          { id: "solar", name: "Solar", description: null },
          { id: "spacelab", name: "Spacelab", description: null },
          {
            id: "superhero",
            name: "Superhero",
            description: null,
          },
          { id: "united", name: "United", description: null },
          { id: "vapor", name: "Vapor", description: null },
          { id: "yeti", name: "Yeti", description: null },
          {
            id: "zephyr",
            name: "Zephyr",
            description: null,
          },
        ],
      },

      setScriptsForWorkAfterDrawLayout: [],
      bodyCustomKeys: {
        notLikeValues: ["sx_custom_page_import"],
        likeSelector: "",
        global: "sx_company_import_global",
        script: "sx_jQuery_codes",
        customPage: "sx_custom_page_import",
        userData: "sx_user_data",
        instantDownload: "sx_instant_download",
      },
    };
  },
  //To use methods in child pages, You can use it from this.$root.METHOD_NAME
  methods: {
    getMessageByKey(key) {
      switch (key) {
        case process.env.VUE_APP_MEMBERSHIP_PASSWORD_RULE_ERROR_KEY:
          return this.$t(
            "PasswordChange.PasswordRulesMessage",
            {},
            { locale: this.$store.state.activeLang }
          );
        case process.env.VUE_APP_MEMBERSHIP_INVALID_EMAIL_OR_PASSWORD_KEY:
          return this.$t(
            "SignIn.InvalidEmailOrPassword",
            {},
            { locale: this.$store.state.activeLang }
          );
        case process.env.VUE_APP_MEMBERSHIP_AUTHENTICATION_ERROR_KEY:
          return this.$t(
            "SignIn.AuthenticationError",
            {},
            { locale: this.$store.state.activeLang }
          );
        case process.env.VUE_APP_MEMBERSHIP_COMPANY_IS_PASSIVE_ERROR_KEY:
          return this.$t(
            "SignIn.CompanyIsPassiveError",
            {},
            { locale: this.$store.state.activeLang }
          );
        case "one_or_more_formulanames_does_not_valid":
          return this.$t(
            "FieldWarnings.FieldFormulaNamesDoesNotValid",
            {},
            { locale: this.$store.state.activeLang }
          );
        case "please_try_again":
          return this.$t(
            "BaseModelFields.PleaseTryAgain",
            {},
            { locale: this.$store.state.activeLang }
          );
        default:
          return key;
      }
    },
    applyTheme() {
      var activeThemeName = localStorage.getItem("activeTheme"),
        bootswatch = this.bootswatch,
        bsThemes = bootswatch.items,
        defaultBsTheme = bsThemes.find((f) => f.id == bootswatch.defaultTheme),
        activeBsTheme = bsThemes.find((f) => f.id == activeThemeName);

      var bsTheme = defaultBsTheme,
        themePrefix = `${bootswatch.folder}/THEME_ID/${bootswatch.file}`,
        filePath = themePrefix.replaceAll("THEME_ID", defaultBsTheme.id),
        title = `${bootswatch.title}|${defaultBsTheme.id}`;
      if (activeBsTheme) {
        bsTheme = activeBsTheme;
        filePath = themePrefix.replaceAll("THEME_ID", activeBsTheme.id);
        title = `${bootswatch.title}|${activeBsTheme.id}`;
      }

      var self = this;
      $.get(filePath, function (data) {
        var css = data,
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");

        style.id = title;
        style.type = "text/css";

        var oldStyles = $(`style[id*="${self.bootswatch.title}"]`);
        if (oldStyles) {
          oldStyles.each(function (i, style) {
            style.remove();
          });
        }

        head.appendChild(style);

        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
      });
    },
    checkSession() {
      var self = this,
        signalR = self.$refs.signalR;

      $(document).on("visibilitychange", function (e) {
        if (document.visibilityState == "hidden") {
          if (signalR) {
            signalR.browserTabIsActive = false;
            // signalR.stopConnection();
            //document.title = signalR.browserTabIsActive;
          }
        } else {
          if (signalR) {
            signalR.browserTabIsActive = true;
            // signalR.startConnection();
            //document.title = signalR.browserTabIsActive;
          }

          if (
            self.$store.state.userPublicId !==
            localStorage.getItem("userPublicId")
          ) {
            window.location.reload();
          }
        }
      });
    },
    encrypt(value, isEncodeURIComponent = false) {
      const secretKey = this.$cryptoJs.enc.Utf8.parse(this.$cryptoSecretKey),
        IV = this.$cryptoJs.enc.Hex.parse(this.$cryptoIvKey),
        encryptedValue = this.$cryptoJs.AES.encrypt(value, secretKey, {
          mode: this.$cryptoJs.mode.CBC,
          padding: this.$cryptoJs.pad.Pkcs7,
          iv: IV,
        }).toString();

      return isEncodeURIComponent
        ? encodeURIComponent(encryptedValue)
        : encryptedValue;
    },
    decrypt(value) {
      const bytes = this.$cryptoJs.AES.decrypt(value, this.$cryptoSecretKey),
        originalText = bytes.toString(this.$cryptoJs.enc.Utf8);
      return originalText;
    },
    isTokenExpired() {
      var currentDate = this.$moment(new Date()).format(),
        tokenExpirationDate = this.$moment(
          localStorage.getItem("tokenExpirationDate"),
          "YYYY-MM-DDTHH:mm:ss"
        ).format();

      return currentDate >= tokenExpirationDate;
    },
    getCurrentUser(isLoginRequest = false, showSuccessMessage = false) {
      var self = this;
      this.$appAxios
        .get("rws-GetCurrentUser")
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            var item = result.item,
              redirectUrl = this.$route.query.redirect,
              redirectUrlIsNull = String.isNullOrWhiteSpace(redirectUrl),
              welcomePageUrl = item.welcomeUrl,
              welcomePageUrlIsNull = String.isNullOrWhiteSpace(welcomePageUrl),
              redirect = this.$appHomeUrl;

            if (isLoginRequest) {
              if (!redirectUrlIsNull) {
                redirect = redirectUrl;
              } else if (!welcomePageUrlIsNull) {
                redirect = welcomePageUrl;
              }

              this.$router.push(redirect);
            }

            this.$store.commit("setUserInfo", item);
            if (!isLoginRequest) {
              this.$store.dispatch("removeBodyClassName", "page-loading");
            }

            if (this.$store.state.isMultiLanguage) {
              this.getCompanyLocalizationValues().then((response) => {
                var result = response.data;
                if (result.isOk) {
                  this.$store.commit("setLocalizationValues", result.values);
                }
              });
            }

            this.getCompanyInformations().then((response) => {
              this.companyImportedDependenciesRemove();
              this.setWindowUserDataFromState();

              if (String.isNullOrWhiteSpace(response)) return;
              var result = response.data;
              if (result.isOk) {
                this.$root.bodyCreateBlock(
                  "style",
                  `${this.bodyCustomKeys.global}_css`,
                  result.globalCss
                );
                this.$root.bodyCreateBlock(
                  "html",
                  `${this.bodyCustomKeys.global}_html`,
                  result.globalHtml
                );
                this.$root.bodyCreateBlock(
                  "script",
                  `${this.bodyCustomKeys.global}_script`,
                  result.globalScript
                );
              }
              this.$store.commit("setCompany", result);
            });

            if (showSuccessMessage) {
              createToast(
                this.$t(
                  "BaseModelFields.CacheDataRefreshed",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
            }
          } else {
            this.signOut();
          }
        })
        .catch(function (error) {
          self.signOut();
        });
    },
    async getFieldsByCustomObjectPublicId(customObjectPublicId) {
      return await this.$appAxios
        .post("/Lcdp-GetCustomObjectFieldsCacheValueById", {
          parentPublicId: customObjectPublicId,
        })
        .catch(function (error) {
          // alert(error);
        });
    },
    async getCompanyInformations() {
      return await this.$appAxios
        .get("/Lcdp-GetCompanyCacheModel")
        .catch(function (error) {
          //alert(error);
        });
    },
    async getCompanyLocalizationValues() {
      return await this.$appAxios
        .get("/Brs-GetLocalizationValuesForCurrentUser")
        .catch(function (error) {
          //alert(error);
        });
    },
    async createKeyForCurrentUser(model) {
      var request = this.$appAxios
        .post("/CreateKeyForCurrentUser", model)
        .catch(function (error) {
          //alert(error);
        });

      return await request;
    },
    getLocalizationValueForPageDesigner(element) {
      if (element.localization && element.localization.length > 0) {
        var translationValue = element.localization.find(
          (f) => f.culture === localStorage.getItem("activeLang")
        );
        if (translationValue) {
          return translationValue;
        }
        return null;
      }
      return null;
    },
    createEvent(name, information) {
      const event = new CustomEvent(name, {
        detail: information,
      });

      // We are dispatching the our custom event here.
      window.dispatchEvent(event);
    },
    setWindowUserDataFromState() {
      $(`#${this.bodyCustomKeys.userData}`).remove();

      var userData = this.$store.getters.userInfo,
        newUserData = {
          activeMenuId: userData.activeMenu.publicId,
          activeMenuName: userData.activeMenu.name,
          buddy: { id: userData.buddy, name: userData.buddyName },
          companyName: userData.company.name,
          delegationPublicId: userData.delegationUserPublicId,
          email: userData.email,
          id: userData.publicId,
          appRecordPublicId: userData.appRecordPublicId,
          isAdmin: userData.isAdmin,
          level1Manager: {
            id: userData.level1Manager,
            name: userData.level1ManagerName,
          },
          level2Manager: {
            id: userData.level2Manager,
            name: userData.level2ManagerName,
          },
          level3Manager: {
            id: userData.level3Manager,
            name: userData.level3ManagerName,
          },
          name: `${userData.firstName} ${userData.lastName}`,
          firstName: userData.firstName,
          lastName: userData.lastName,
          permissionGroupNames: userData.permissionGroups
            .map((m) => m.name)
            .join(this.$systemSeparator),
          permissionGroupIds: userData.permissionGroups
            .map((m) => m.publicId)
            .join(this.$systemSeparator),
          permissionGroups: userData.permissionGroups,
          permissionsOfActions: userData.permissionsOfActions
            .map((m) => m.actionName)
            .join(this.$systemSeparator),
          specificCode1: userData.specificCode1,
          specificCode2: userData.specificCode2,
          specificCode3: userData.specificCode3,
          userGroupNames: userData.userGroups
            .map((m) => m.name)
            .join(this.$systemSeparator),
          userGroupIds: userData.userGroups
            .map((m) => m.publicId)
            .join(this.$systemSeparator),
          userGroups: userData.userGroups,
        };

      this.bodyCreateBlock(
        "script",
        this.bodyCustomKeys.userData,
        `var userData=${JSON.stringify(newUserData)};`
      );
    },
    setWindowCompanyDataFromState() {
      var companyData = this.$store.getters.getCompanyData;
      if (companyData && companyData.isOk) {
        this.$root.bodyCreateBlock(
          "style",
          `${this.bodyCustomKeys.global}_css`,
          companyData.globalCss
        );
        this.$root.bodyCreateBlock(
          "html",
          `${this.bodyCustomKeys.global}_html`,
          companyData.globalHtml
        );
        this.$root.bodyCreateBlock(
          "script",
          `${this.bodyCustomKeys.global}_script`,
          companyData.globalScript
        );
      }
    },
    formItemNewChangeEvent(fieldPublicIds) {
      if (!fieldPublicIds || !Array.isArray(fieldPublicIds)) return null;

      //# !! loop risk
      fieldPublicIds.forEach((fieldPublicId) => {
        var formItem = document.getElementById(fieldPublicId),
          event = new Event("change");
        formItem.dispatchEvent(event);
      });
    },
    newUIEvent(name, fieldPublicIds) {
      if (
        String.isNullOrWhiteSpace(name) ||
        !fieldPublicIds ||
        !Array.isArray(fieldPublicIds)
      )
        return null;

      //# !! loop risk
      fieldPublicIds.forEach((fieldPublicId) => {
        var formItem = document.getElementById(fieldPublicId),
          event = new UIEvent(name);
        formItem.dispatchEvent(event);
      });
    },
    copyClipboard(text, leftMessage) {
      navigator.clipboard.writeText(text);

      var copiedMessage = this.$t(
          "BaseModelFields.Copied",
          {},
          { locale: this.$store.state.activeLang }
        ),
        message = copiedMessage;
      if (!String.isNullOrWhiteSpace(leftMessage)) {
        message = String.format("{0} {1}", leftMessage, copiedMessage);
      }

      createToast(message, {
        showIcon: "true",
        position: "top-right",
        type: "success",
        transition: "zoom",
      });
    },
    getElementWidth(element) {
      return String.isNullOrWhiteSpace(element.width()) ? 0 : element.width();
    },
    getLocation() {
      var self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            var location = String.format(
              "{0}|{1}",
              position.coords.latitude,
              position.coords.longitude
            );
            $("#CurrentLocation").val(location);
            Object.setCookie("CurrentLocation", location, 1);
          },
          function (error) {
            switch (error.code) {
              case error.POSITION_UNAVAILABLE:
              case error.TIMEOUT:
              case error.UNKNOWN_ERROR:
                self.getLocation();
                break;
              default:
                break;
            }
            // self.getLocation();
          }
        );
      }
    },
    signOut() {
      var path = this.$route.fullPath,
        query = {};
      if (!this.$route.meta.isHelpPage) {
        query = {
          redirect: path,
        };
      }

      this.companyImportedDependenciesRemove();

      this.$store.commit("logOutUser");
      this.$router.push({
        path: "/sign-in",
        query: query,
      });
    },
    companyImportedDependenciesRemove() {
      $(this.bodyCustomKeys.likeSelector).remove();
      window.userData = null;
    },
    setI18nLocaleValue(code) {
      this.$i18n.locale = code;
      this.$i18n.global.locale.value = code;
      this.$store.commit("setActiveLang", code);
    },
    setCurrentUserLanguage(code, isUserNotAuth = false) {
      var oldLocale = this.$i18n.locale;

      if (isUserNotAuth) {
        this.setI18nLocaleValue(code);
        return;
      }

      // createToast(
      //   this.$t(
      //     "BaseModelFields.ChangeLanguageWarning",
      //     {},
      //     { locale: this.$store.state.activeLang }
      //   ),
      //   {
      //     showIcon: true,
      //     position: "top-left",
      //     type: "warning",
      //     transition: "zoom",
      //   }
      // );

      this.$appAxios
        .post(`/Lcdp-ChangeUserActiveLanguage/${code}`)
        .then((response) => {
          var result = response.data,
            localeValue = result.isOk ? code : oldLocale;

          this.setI18nLocaleValue(localeValue);
          locale(localeValue);

          if (!result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.ChangeUserActiveLanguageError",
                {},
                { locale: localeValue }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          } else {
            this.signOut();

            createToast(
              this.$t(
                "BaseModelFields.ChangeLanguageMessage",
                {},
                { locale: localeValue }
              ),
              {
                showIcon: true,
                position: "top-left",
                type: "warning",
                transition: "zoom",
              }
            );
          }
        });
    },
    checkElementAttribute(elementId, attr) {
      const element = document.getElementById(elementId);
      if (element.hasAttribute(attr)) {
        return true;
      }
      return false;
    },
    checkElementHasClass(element, className) {
      return $(element).hasClass(className);
    },
    changeDocumentTitle(text) {
      document.title = String.format(
        String.isNullOrWhiteSpace(text) ? "{1}" : "{0} / {1}",
        text,
        this.$config.localization[this.$store.state.activeLang].base.appTitle
      );
    },
    select2SetValue(select2, ObjOrIsMultiple, ids, texts, isCellEdit) {
      var options = select2.find("option");
      if (options.length > 0) options.remove();

      if (String.isNullOrWhiteSpace(ids) || String.isNullOrWhiteSpace(texts))
        return;

      if (
        Object.isObject(ObjOrIsMultiple)
          ? ObjOrIsMultiple.isMultipleValue
          : ObjOrIsMultiple
      ) {
        ids = ids.split(this.$systemSeparator);
        texts = texts.split(this.$systemSeparator);

        ids.forEach((id, i) => {
          select2.append(
            $(`<option selected value="${id}">${texts[i]}</option>`)
          );
        });
      } else {
        if (ids.includes(this.$systemSeparator))
          ids = ids.split(this.$systemSeparator)[0];

        if (texts.includes(this.$systemSeparator))
          texts = texts.split(this.$systemSeparator)[0];

        select2.append($(`<option selected value="${ids}">${texts}</option>`));
      }

      // if (isCellEdit) {
      select2.trigger("change");
      // }
    },
    getSelect2Locale(lang) {
      if (lang == "tr") {
        return {
          errorLoading: function () {
            return "Sonuç yüklenemedi";
          },
          inputTooLong: function (args) {
            var overChars = args.input.length - args.maximum;
            var message = overChars + " karakter daha girmelisiniz";
            return message;
          },
          inputTooShort: function (args) {
            var remainingChars = args.minimum - args.input.length;
            var message =
              "En az " + remainingChars + " karakter daha girmelisiniz";
            return message;
          },
          loadingMore: function () {
            return "Daha fazla…";
          },
          maximumSelected: function (args) {
            var message = "Sadece " + args.maximum + " seçim yapabilirsiniz";
            return message;
          },
          noResults: function () {
            return "Sonuç bulunamadı";
          },
          searching: function () {
            return "Aranıyor…";
          },
          removeAllItems: function () {
            return "Tüm öğeleri kaldır";
          },
          removeItem: function () {
            return "Bu öğeyi kaldır";
          },
          search: function () {
            return "Ara";
          },
        };
      }
      return {};
    },
    localizationForSelect2Items(type, items, fieldProperties = {}) {
      var localizedItems = [];
      switch (type) {
        case "operators":
          items.forEach((item) => {
            localizedItems.push({
              id: item.id,
              text: this.$t(
                "FieldOperators." + item.text,
                {},
                { locale: this.$store.state.activeLang }
              ),
              originalText: item.text,
            });
          });
          break;
        case "fields":
          items.forEach((item) => {
            var text = item.text,
              fieldLocalizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  parentId: fieldProperties.customObject.publicId,
                  itemPublicId: item.id,
                  itemTypeId: 2,
                });
            if (fieldLocalizationValue) {
              text = fieldLocalizationValue.value;
            }

            localizedItems.push({
              id: item.id,
              text: text,
              originalText: item.text,
            });
          });
          break;
        default:
          return items;
      }
      return localizedItems;
    },
    getFieldRequestUrl(fieldType, params) {
      var fieldTypesAjax = this.fieldTypes.ajax,
        fieldAjaxDetail = fieldTypesAjax[fieldType];

      if (!fieldAjaxDetail) return null;

      var parameterKeyValues = [];
      if (fieldAjaxDetail.parameters) {
        fieldAjaxDetail.parameters.forEach((parameterKey) => {
          var parameterValue = params[parameterKey];
          if (String.isNullOrWhiteSpace(parameterValue)) return;

          parameterKeyValues.push({ key: parameterKey, value: parameterValue });
        });
      }

      var requestUrl = String.createGetUrl(
        fieldAjaxDetail.url,
        parameterKeyValues
      );
      // console.log(requestUrl);

      return requestUrl;
    },
    getSelect2AjaxConfiguration(field, ajaxUrl) {
      var axios = this.$appAxios,
        isSelectlist =
          field.fieldType == "Checkbox" || field.fieldType == "SelectList",
        pageSize = parseInt(process.env.VUE_APP_SELECT2_PAGE_SIZE),
        self = this;

      return {
        url: ajaxUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        dataType: "json",
        delay: 250,
        data: function (params) {
          var obj = {
            q: "",
          };

          if (
            !String.isNullOrWhiteSpace(params.term) &&
            !field.isCustomMatcher
          ) {
            obj.q = params.term;
          }

          if (!isSelectlist) {
            obj.pageSize = pageSize;
            obj.pageNumber = params.page || 1;
          }

          return obj;
        },
        processResults: function (data, params) {
          var more = isSelectlist
              ? false
              : data.hasNextPage && data.items.length > 0,
            result = [],
            items = isSelectlist ? data : data.items;

          items.forEach((item) => {
            result.push({ id: item.key, text: item.value });
          });

          var select2SearchBox = document.querySelector(
            ".select2-container--open .select2-search__field"
          );
          if (select2SearchBox) {
            select2SearchBox.focus();
          }

          var fieldValues = this.$element.val();
          if (!String.isNullOrWhiteSpace(fieldValues)) {
            result = result.filter((f) => {
              return !fieldValues.includes(f.id);
            });
          }

          if (ajaxUrl.includes("rws-Operators")) {
            result = self.localizationForSelect2Items("operators", result);
          } else if (
            ajaxUrl.includes("rws-FilterableFields") &&
            self.$store.state.isMultiLanguage
          ) {
            result = self.localizationForSelect2Items("fields", result, field);
          }

          if (!isSelectlist) {
            //for lookup field values
            if (data.pageNumber === 0) {
              data.pageNumber = params.page || 1;

              if (data.items.length > 0) {
                more = true;
              }
            }

            if (data.items.length < pageSize) {
              more = false;
            }
          }

          if (
            params &&
            !String.isNullOrWhiteSpace(params.term) &&
            field.isCustomMatcher
          ) {
            var searchText = String.searchTextReplace(
              params.term
            ).toLowerCase();
            result = result.filter(
              (f) =>
                String.searchTextReplace(f.text)
                  .toLowerCase()
                  .indexOf(searchText) > -1
            );
          }

          return { results: result, pagination: { more: more } };
        },
        transport: function (params, success, failure) {
          // if (params.data.q && field.isCustomMatcher) {
          //   var request = $.ajax(params);
          //   request.abort();

          //   return request;
          // } else {

          // }
          axios
            .get(this.url, { params: this.data })
            .then(function (response) {
              success(response.data);
            })
            .catch(function (error) {
              failure(error);
            });
        },
        escapeMarkup: function (m) {
          return m;
        },
      };
    },
    async validationProcess(validations) {
      if (!validations || (validations && validations.length == 0))
        return false;

      var isBlock = validations.filter(function (f) {
          return f.validationType.value == 4 || f.validationType.value == 5;
        }),
        isForward = validations.filter(function (f) {
          return f.validationType.value == 6 || f.validationType.value == 7;
        });
      if (isBlock.length > 0) {
        var isEditBlock = isBlock[0].validationType.value == 5,
          isDetailBlock = isBlock[0].validationType.value == 4,
          redirectRouteName = isEditBlock ? "EditBlock" : "DetailBlock";

        this.$store.commit(
          "setValidationRuleWarnings",
          isBlock.map((u) => u.message)
        );
        this.$router.push({
          name: redirectRouteName,
          params: {
            key: this.$route.params.key,
          },
        });
        return true;
      } else if (isForward.length > 0) {
        var firstForward = isForward[0],
          routeName = this.$route.meta.isDetailPage ? "detail" : "edit";
        this.$router.push({
          name: routeName,
          params: {
            key: this.$route.params.key,
            id: this.$route.params.id,
          },
          query: {
            pageLayoutId: firstForward.value,
            // isForward: true,
          },
        });
        return true;
      }

      return false;
    },

    //Start File Functions
    getFileNameByResponse(response, defaultFileName) {
      var fileName = defaultFileName,
        disposition = response.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1])
          fileName = matches[1].replace(/['"]/g, "");
      }

      return fileName;
    },
    downloadViewFilterExportFile(requestUrl, fileName, model = null, dropdown) {
      this.$appAxios
        .post(requestUrl, model, {
          responseType: "blob",
        })
        .then((response) => {
          this.dropdownLoadingToggle(dropdown);
          this.exportFileResultAfterOperation(
            response.data,
            this.getFileNameByResponse(response, fileName)
          );
        });
    },
    downloadTemplate(requestUrl, fileName, dropdown) {
      this.$appAxios
        .get(requestUrl, {
          responseType: "blob",
        })
        .then((response) => {
          if (dropdown) this.dropdownLoadingToggle(dropdown);
          this.exportFileResultAfterOperation(
            response.data,
            this.getFileNameByResponse(response, fileName)
          );
        });
    },
    dropdownLoadingToggle(dropdown) {
      var button = dropdown.find("button"),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      button.prop("disabled", false).addClass("dropdown-toggle");
      firstSpan.show();
      loadingBlock.hide();
    },
    async getBlobErrorView(data) {
      return JSON.parse(await data.text());
    },
    exportFileResultAfterOperation(result, fileName) {
      if (result.type == "application/json") {
        const error = this.getBlobErrorView(result);
        error.then((error) => {
          var message = error.msg;
          if (String.isNullOrWhiteSpace(message)) {
            message = this.$t(
              "BaseModelFields.UnexpectedErrorHasOccurred",
              {},
              { locale: this.$store.state.activeLang }
            );
          }
          createToast(message, {
            showIcon: true,
            position: "top-right",
            type: error.isOk ? "success" : "danger",
            transition: "zoom",
          });
        });
      } else {
        var fileURL = window.URL.createObjectURL(result),
          directLink = document.createElement("a");

        directLink.id = this.bodyCustomKeys.instantDownload;
        directLink.href = fileURL;
        directLink.setAttribute("download", fileName);
        document.body.appendChild(directLink);

        directLink.click();
        directLink.remove();
      }
    },
    downloadFile(item) {
      this.$appAxios
        .get(`/rws-DocumentController-Get/${item.id}`, {
          responseType: "blob",
        })
        .then((response) => {
          var result = response.data;
          if (result.type == "application/json") {
            const error = this.getBlobErrorView(result);
            error.then((error) => {
              var message = error.msg;
              if (String.isNullOrWhiteSpace(message)) {
                message = this.$t(
                  "BaseModelFields.UnexpectedErrorHasOccurred",
                  {},
                  { locale: this.$store.state.activeLang }
                );
              } else if (
                message === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY
              ) {
                message = this.$t(
                  "Components.PermissionNone.DownloadDocumentsMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                );
              }

              createToast(message, {
                showIcon: true,
                position: "top-right",
                type: error.isOk ? "success" : "danger",
                transition: "zoom",
              });
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([result])),
              directLink = document.createElement("a");

            directLink.id = this.bodyCustomKeys.instantDownload;
            directLink.href = fileURL;
            directLink.setAttribute("download", item.name);
            document.body.appendChild(directLink);

            directLink.click();
            directLink.remove();
          }
        });
    },
    previewDocumentOrImage(documentPublicId, documentOldPublicId, field) {
      this.$appAxios
        .get(`/rws-DocumentController-Get/${documentPublicId}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (!String.isNullOrWhiteSpace(documentOldPublicId)) {
            var oldPreviewBlock = $(`.preview_${documentOldPublicId}`);
            oldPreviewBlock.attr(
              "class",
              `preview_${documentPublicId} img-fluid border`
            );
          } else if (!String.isNullOrWhiteSpace(field)) {
            if (
              field.property === "only_view_on_modal" ||
              field.property === "only_view_on_detail"
            ) {
              var modalImagePreview = $(
                  "#modalImageView_" + field.publicId
                ).find(".modal-body"),
                previewBlock = modalImagePreview.find(
                  ".preview_" + documentPublicId
                );
              if (previewBlock.length == 0) {
                modalImagePreview.append(
                  $("<img/>").attr({
                    class: String.format(
                      "img-fluid border preview_{0}",
                      documentPublicId
                    ),
                  })
                );
              }
            }
          }

          var blobURL = URL.createObjectURL(response.data);
          var images = document.getElementsByClassName(
            `preview_${documentPublicId}`
          );
          // images.onload = function () {
          //   URL.revokeObjectURL(blobURL);
          // };
          for (const image of images) {
            // image.onload = function () {
            //   URL.revokeObjectURL(blobURL);
            // };
            $(image).attr("src", blobURL);
          }
        });
    },
    isDocumentViewerFile(name) {
      var tolowerFileName = name.toLowerCase();
      return (
        tolowerFileName.includes(".doc") ||
        tolowerFileName.includes(".xls") ||
        tolowerFileName.includes(".png") ||
        tolowerFileName.includes(".jpg") ||
        tolowerFileName.includes(".jpeg") ||
        tolowerFileName.includes(".pdf")
      );
    },
    //End File Functions

    //Start SetXRM Custom Button Functions
    viewMapWithSwal(fieldName, lat, lng) {
      Swal.fire({
        title: `<span class="fs-4">${fieldName}</span>`,
        html: "<div id='leaflet-map-container'></div>",
        width: this.$isMobile() ? "100%" : "60%",
        confirmButtonText:
          "<i class='fa fa-times'></i>" +
          this.$t(
            "BaseModelFields.Close",
            {},
            { locale: this.$store.state.activeLang }
          ),
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn fw-bold btn-success",
        },
      });

      var spatialFieldSetting = this.fieldTypes.settings.spatial,
        map = leafletJS
          .map("leaflet-map-container")
          .setView([lat, lng], spatialFieldSetting.zoom);

      leafletJS.Icon.Default.mergeOptions({
        iconRetinaUrl: require("@/assets/img/leaflet/marker-icon-2x.png"),
        iconUrl: require("@/assets/img/leaflet/marker-icon.png"),
      });

      leafletJS
        .tileLayer(spatialFieldSetting.mapUrl, {
          maxZoom: spatialFieldSetting.maxZoom,
          minZoom: spatialFieldSetting.minZoom,
          zoomAnimation: spatialFieldSetting.zoomAnimation,
        })
        .addTo(map);

      leafletJS
        .marker([lat, lng])
        .bindTooltip(`${lat},${lng}`, {
          permanent: true,
          direction: "right",
          opacity: 0.8,
        })
        .addTo(map);
    },
    counterAnimationReady() {
      $(".counter").each(function () {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text(),
            },
            {
              duration: 2000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
            }
          );
      });
    },
    addModalLoading(selector) {
      selector.html(
        `<div id="loading-bar"><div class="d-flex align-items-center justify-content-center h-100 w-100"><div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div></div><b class="d-flex align-items-center mt-2 mb-0 justify-content-center font-weight-normal">${this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )}</b></div>`
      );
    },
    createFrameAndCalculateHeight(appendSelector, modal, src) {
      var iframeId = `iframe_${String.newGuid()}`;
      appendSelector.append(
        `<iframe src="${src}" class="w-100 sx-iframe" id="${iframeId}" frameborder="0" style="display:none;"></iframe>`
      );

      $(`#${iframeId}`).on("load", function () {
        var iframe = $(`#${iframeId}`),
          iframeContent = iframe.contents(),
          modalBody = modal.find(".modal-body"),
          modalHeaderAndFooterWidthHeightValue = 200,
          windowHeight = $(window).height();

        modalBody.addClass("p-0");
        modalBody.find("#loading-bar").remove();

        iframe.show();
        Object.IframeSettings(iframeId);
        document.getElementById(iframeId).height =
          windowHeight - modalHeaderAndFooterWidthHeightValue;
      });
    },
    async validateButton(customObjectKey, recordPublicId, buttonPublicId) {
      this.triggeredButtonRequestIsLoading = true;
      var request = this.$appAxios
        .post(
          `/rws-BusinessRuleController-ButtonTrigger?customObjectKey=${customObjectKey}&recordPublicId=${recordPublicId}&buttonPublicId=${buttonPublicId}`
        )
        .finally(() => {
          this.triggeredButtonRequestIsLoading = false;
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });

      return await request;
    },
    async getFormTemplateDetail(formTemplateId, customObjectPublicId) {
      var model = {
        publicId: formTemplateId,
        parentPublicId: customObjectPublicId,
      };
      var request = this.$appAxios
        .post(`/Lcdp-GetFormTemplateCacheValueByKey`, model)
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });

      return await request;
    },
    async getRecordByFields(customObjectKey, recordId, formulaNames) {
      var model = {
        customObjectKey: customObjectKey,
        recordPublicId: recordId,
        fieldFormulaNames: formulaNames,
      };
      var request = this.$appAxios
        .post("/rws-SetController-GetRecordByFields", model)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            return result.fieldValues;
          } else {
            var message =
              this.getMessageByKey(result.message) ||
              this.getMessageByKey(result.msg) ||
              this.$t(
                "BaseModelFields.ErrorMessage",
                {},
                { locale: this.$store.state.activeLang }
              );
            createToast(message, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
            return null;
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });

      return await request;
    },
    setDefaultValuesForTriggeredButton(button) {
      this.triggeredButton = button;
      this.triggeredButtonRequestIsLoading = true;
      this.triggeredButtonResponse = {
        isOk: false,
        message: null,
        businessRuleResponses: [],
        validations: [],
      };

      var buttonModal = $("#buttonModal"),
        buttonModalBody = buttonModal.find(".modal-body");

      buttonModalBody.removeClass("p-0");
      buttonModalBody.find(".iframe-block").html("");
    },
    businessRuleButtonTrigger(currentButton, customObjectKey, recordPublicId) {
      if (
        String.isNullOrWhiteSpace(currentButton) ||
        String.isNullOrWhiteSpace(customObjectKey) ||
        String.isNullOrWhiteSpace(recordPublicId)
      )
        return;

      this.setDefaultValuesForTriggeredButton(currentButton);

      this.validateButton(
        customObjectKey,
        recordPublicId,
        currentButton.publicId
      )
        .then((response) => {
          this.triggeredButtonResponse = response.data;
          this.triggeredButtonRequestIsLoading = false;

          var notificationType = this.triggeredButtonResponse.notificationType;
          if (notificationType == "Tooltip") {
            if (
              currentButton.isPageRefreshAfterProcessDone &&
              this.triggeredButtonResponse.isOk
            ) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }

            createToast(
              this.triggeredButtonResponse.isOk
                ? this.$t(
                    "BaseModelFields.SuccessMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                : this.$t(
                    "BaseModelFields.ErrorMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
              {
                showIcon: true,
                position: "top-right",
                type: this.triggeredButtonResponse.isOk ? "success" : "danger",
                transition: "zoom",
              }
            );
          } else {
            showModal(document.getElementById("buttonModal"));
          }
        })
        .catch((err) => {
          //TODO: Error
        });
    },
    directForwardButtonTrigger(currentButton, customObjectKey, recordPublicId) {
      if (currentButton.isPermissionApply) {
        this.setDefaultValuesForTriggeredButton(currentButton);
        this.validateButton(
          customObjectKey,
          recordPublicId,
          currentButton.publicId
        )
          .then((response) => {
            var result = response.data;
            this.triggeredButtonResponse = response.data;
            this.triggeredButtonRequestIsLoading = false;

            if (this.triggeredButtonResponse.isOk) {
              this.buttonOperation(currentButton, {});
            } else {
              this.triggeredButton = currentButton;
              showModal(document.getElementById("buttonModal"));
            }
          })
          .catch((err) => {
            //TODO: Error
          });
      } else {
        this.buttonOperation(currentButton, {});
      }
    },
    customForwardButtonTriggerNoPageDataValue(
      currentButton,
      currentField,
      requestModel,
      customObjectKey,
      recordPublicId,
      calculateFormulaRequest = {}
    ) {
      if (currentButton.isPermissionApply) {
        this.setDefaultValuesForTriggeredButton(currentButton);
        this.validateButton(
          customObjectKey,
          recordPublicId,
          currentButton.publicId
        )
          .then((response) => {
            var result = response.data;
            this.triggeredButtonResponse = result;
            this.triggeredButtonRequestIsLoading = false;

            if (this.triggeredButtonResponse.isOk) {
              if (currentButton.sendDefaultValueWithButton) {
                this.getCalculateDefaultValueWithButton(
                  calculateFormulaRequest
                ).then((result) => {
                  localStorage.removeItem("TriggeredData");
                  if (result.isOk) {
                    localStorage.setItem(
                      "TriggeredData",
                      JSON.stringify(result.keyValues)
                    );
                  }

                  this.buttonOperation(currentButton, {
                    field: currentField,
                    valueAvailable: false,
                    requestModel: requestModel,
                  });
                });
              } else {
                this.buttonOperation(currentButton, {
                  field: currentField,
                  valueAvailable: false,
                  requestModel: requestModel,
                });
              }
            } else {
              this.triggeredButton = currentButton;
              showModal(document.getElementById("buttonModal"));
            }
          })
          .catch((err) => {
            //TODO: Error
          });
      } else {
        if (currentButton.sendDefaultValueWithButton) {
          this.getCalculateDefaultValueWithButton(calculateFormulaRequest).then(
            (result) => {
              localStorage.removeItem("TriggeredData");
              if (result.isOk) {
                localStorage.setItem(
                  "TriggeredData",
                  JSON.stringify(result.keyValues)
                );
              }

              this.buttonOperation(currentButton, {
                field: currentField,
                valueAvailable: false,
                requestModel: requestModel,
              });
            }
          );
        } else {
          this.buttonOperation(currentButton, {
            field: currentField,
            valueAvailable: false,
            requestModel: requestModel,
          });
        }
      }
    },
    customForwardButtonTrigger(
      currentButton,
      currentField,
      pageDataValue,
      customObjectKey,
      recordPublicId,
      calculateFormulaRequest = {}
    ) {
      if (currentButton.isPermissionApply) {
        this.setDefaultValuesForTriggeredButton(currentButton);
        this.validateButton(
          customObjectKey,
          recordPublicId,
          currentButton.publicId
        )
          .then((response) => {
            var result = response.data;
            this.triggeredButtonResponse = result;
            this.triggeredButtonRequestIsLoading = false;

            if (this.triggeredButtonResponse.isOk) {
              if (currentButton.sendDefaultValueWithButton) {
                this.getCalculateDefaultValueWithButton(
                  calculateFormulaRequest
                ).then((result) => {
                  localStorage.removeItem("TriggeredData");
                  if (result.isOk) {
                    localStorage.setItem(
                      "TriggeredData",
                      JSON.stringify(result.keyValues)
                    );
                  }

                  this.buttonOperation(currentButton, {
                    field: currentField,
                    valueAvailable: true,
                    value: pageDataValue,
                  });
                });
              } else {
                this.buttonOperation(currentButton, {
                  field: currentField,
                  valueAvailable: true,
                  value: pageDataValue,
                });
              }
            } else {
              this.triggeredButton = currentButton;
              showModal(document.getElementById("buttonModal"));
            }
          })
          .catch((err) => {
            //TODO: Error
          });
      } else {
        if (currentButton.sendDefaultValueWithButton) {
          this.getCalculateDefaultValueWithButton(calculateFormulaRequest).then(
            (result) => {
              localStorage.removeItem("TriggeredData");
              if (result.isOk) {
                localStorage.setItem(
                  "TriggeredData",
                  JSON.stringify(result.keyValues)
                );
              }

              this.buttonOperation(currentButton, {
                field: currentField,
                valueAvailable: true,
                value: pageDataValue,
              });
            }
          );
        } else {
          this.buttonOperation(currentButton, {
            field: currentField,
            valueAvailable: true,
            value: pageDataValue,
          });
        }
      }
    },
    formTemplateButtonTrigger(
      currentButton,
      customObjectKey,
      recordPublicId,
      recordDetail,
      allFields
    ) {
      if (currentButton.isPermissionApply) {
        this.setDefaultValuesForTriggeredButton(currentButton);
        this.validateButton(
          customObjectKey,
          recordPublicId,
          currentButton.publicId
        )
          .then((response) => {
            var result = response.data;
            this.triggeredButtonResponse = response.data;
            this.triggeredButtonRequestIsLoading = false;

            if (this.triggeredButtonResponse.isOk) {
              this.buttonOperation(currentButton, recordDetail, allFields);
            } else {
              this.triggeredButton = currentButton;
              showModal(document.getElementById("buttonModal"));
            }
          })
          .catch((err) => {
            //TODO: Error
          });
      } else {
        this.buttonOperation(currentButton, recordDetail, allFields);
      }
    },
    buttonApproveOperationForGrid(
      self,
      currentButton,
      customObjectKey,
      recordPublicId
    ) {
      this.triggeredButton = currentButton;
      this.triggeredButton.parent = self;
      this.triggeredButton.isGrid = true;
      this.triggeredButton.customObjectKey = customObjectKey;
      this.triggeredButton.recordPublicId = recordPublicId;
      showModal(document.getElementById("buttonApproveModal"));
    },
    buttonApproveOperationForTree(
      self,
      currentButton,
      customObjectKey,
      recordPublicId,
      record,
      fields
    ) {
      this.triggeredButton = currentButton;
      this.triggeredButton.parent = self;
      this.triggeredButton.isTree = true;
      this.triggeredButton.customObjectKey = customObjectKey;
      this.triggeredButton.recordPublicId = recordPublicId;
      this.triggeredButton.record = record;
      this.triggeredButton.fields = fields;
      showModal(document.getElementById("buttonApproveModal"));
    },
    buttonApproveOperation(self, currentButton) {
      this.triggeredButton = currentButton;
      this.triggeredButton.parent = self;
      showModal(document.getElementById("buttonApproveModal"));
    },
    buttonApproved(button) {
      if (button.isGrid) {
        button.parent.clickedCustomButtonOperation(
          button,
          button.customObjectKey,
          button.recordPublicId
        );
      } else if (button.isTree) {
        button.parent.clickedCurrentButton = button;
        button.parent.clickedCustomButton(
          // button,
          button.customObjectKey,
          button.recordPublicId,
          button.record,
          button.fields
        );
      } else {
        //set pages
        button.parent.operation();
      }
    },
    getReportingWebSiteUrl() {
      var reportDomain = process.env.VUE_APP_REPORT_LOCAL_WEB_SITE_URL;
      if (process.env.NODE_ENV !== "development") {
        if (this.$config.isCustomConfig) {
          reportDomain = this.$config.webSites.report;
        } else {
          reportDomain = process.env.VUE_APP_REPORT_WEB_SITE_URL;
        }
      }
      return reportDomain;
    },
    buttonOperation(currentButton, detail, allFields) {
      var modal = $("#buttonModal"),
        modalFrameBlock = modal.find(".iframe-block"),
        isLightBox = currentButton.forwardTypeName === "LightBox",
        isDirectForward = currentButton.forwardTypeName === "DirectForward",
        isNewTabForward = currentButton.forwardTypeName === "NewTab";

      this.setDefaultValuesForTriggeredButton(currentButton);

      if (currentButton.buttonTypeId == 2) {
        if (String.isNullOrWhiteSpace(currentButton.staticLink)) {
          createToast(
            this.$t(
              "BaseModelFields.RedirectUrlNullOrNotValid",
              {},
              { locale: this.$store.state.activeLang }
            ),
            {
              showIcon: true,
              position: "top-right",
              type: "warning",
              transition: "zoom",
            }
          );
          return;
        }

        if (isLightBox) {
          this.addModalLoading(modalFrameBlock);
          this.triggeredButtonRequestIsLoading = false;
          showModal(document.getElementById("buttonModal"));
        }

        if (isDirectForward) {
          window.open(currentButton.staticLink, "_self");
        } else if (isNewTabForward) {
          window.open(currentButton.staticLink, "_blank");
        } else if (isLightBox) {
          this.createFrameAndCalculateHeight(
            modalFrameBlock,
            modal,
            currentButton.staticLink
          );
        }
      } else if (currentButton.buttonTypeId == 3) {
        if (detail.valueAvailable) {
          if (isLightBox) {
            this.addModalLoading(modalFrameBlock);
            this.triggeredButtonRequestIsLoading = false;
            showModal(document.getElementById("buttonModal"));
          }

          if (String.isNullOrWhiteSpace(detail.value)) {
            if (isLightBox) {
              modalFrameBlock.html(
                String.format(
                  '<div class="alert alert-warning mb-0 fs-5">{0}</div>',
                  this.$t(
                    "BaseModelFields.RedirectUrlNullOrNotValid",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                )
              );
            } else {
              createToast(
                this.$t(
                  "BaseModelFields.RedirectUrlNullOrNotValid",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "warning",
                  transition: "zoom",
                }
              );
            }

            return;
          }

          if (isDirectForward) {
            window.open(detail.value, "_self");
          } else if (isNewTabForward) {
            window.open(detail.value, "_blank");
          } else if (isLightBox) {
            this.createFrameAndCalculateHeight(
              modalFrameBlock,
              modal,
              detail.value
            );
          }
        } else {
          this.getRecordByFields(
            detail.requestModel.customObjectKey,
            detail.requestModel.recordPublicId,
            detail.requestModel.formulaNames
          ).then((fieldValues) => {
            if (fieldValues) {
              var valueObj = fieldValues[detail.field.formulaName];

              if (isLightBox) {
                this.addModalLoading(modalFrameBlock);
                this.triggeredButtonRequestIsLoading = false;
                showModal(document.getElementById("buttonModal"));
              }

              var url = valueObj.value;
              if (String.isNullOrWhiteSpace(url)) {
                if (isLightBox) {
                  modalFrameBlock.html(
                    String.format(
                      '<div class="alert alert-warning mb-0 fs-5">{0}</div>',
                      this.$t(
                        "BaseModelFields.RedirectUrlNullOrNotValid",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    )
                  );
                } else {
                  createToast(
                    this.$t(
                      "BaseModelFields.RedirectUrlNullOrNotValid",
                      {},
                      { locale: this.$store.state.activeLang }
                    ),
                    {
                      showIcon: true,
                      position: "top-right",
                      type: "warning",
                      transition: "zoom",
                    }
                  );
                }
                return;
              }

              if (isDirectForward) {
                window.open(url, "_self");
              } else if (isNewTabForward) {
                window.open(url, "_blank");
              } else if (isLightBox) {
                this.createFrameAndCalculateHeight(modalFrameBlock, modal, url);
              }
            }
          });
        }
      } else if (currentButton.buttonTypeId == 4) {
        if (String.isNullOrWhiteSpace(currentButton.formTemplatePublicId)) {
          createToast(
            this.$t(
              "BaseModelFields.FormTemplateNullOrEmpty",
              {},
              { locale: this.$store.state.activeLang }
            ),
            {
              showIcon: true,
              position: "top-right",
              type: "warning",
              transition: "zoom",
            }
          );
          return;
        }

        this.triggeredButtonRequestIsLoading = true;
        this.getFormTemplateDetail(
          currentButton.formTemplatePublicId,
          currentButton.customObjectPublicId
        )
          .then((response) => {
            var formTemplateDetail = response.data;

            if (!formTemplateDetail) {
              createToast(
                this.$t(
                  "BaseModelFields.FormTemplateNotFound",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "danger",
                  transition: "zoom",
                }
              );
              return;
            }

            if (
              !formTemplateDetail.isPreviewEnabled &&
              (formTemplateDetail.reportTypeId == 2 ||
                formTemplateDetail.reportTypeId == 3)
            ) {
              createToast(
                this.$t(
                  "BaseModelFields.FormTemplateNotPreview",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "warning",
                  transition: "zoom",
                }
              );
              return;
            }

            if (isLightBox) {
              this.addModalLoading(modalFrameBlock);
              this.triggeredButtonRequestIsLoading = false;
              showModal(document.getElementById("buttonModal"));
            }

            if (formTemplateDetail.reportTypeId == 1) {
              //word
              this.downloadTemplate(
                String.format(
                  "Brs-ExportController-Get?templatePublicId={0}&customObjectPublicId={1}&recordPublicId={2}",
                  currentButton.formTemplatePublicId,
                  currentButton.customObjectPublicId,
                  detail["PUBLICID"]
                ),
                currentButton.formTemplateName,
                null
              );
              if (isLightBox) {
                modalFrameBlock.html(
                  String.format(
                    '<div class="alert alert-success mb-0 fs-5">{0}</div>',
                    this.$t(
                      "BaseModelFields.SuccessMessage",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  )
                );
              } else {
                createToast(
                  this.$t(
                    "BaseModelFields.SuccessMessage",
                    {},
                    { locale: this.$store.state.activeLang }
                  ),
                  {
                    showIcon: true,
                    position: "top-right",
                    type: "success",
                    transition: "zoom",
                  }
                );
              }
            } else if (
              formTemplateDetail.reportTypeId == 2 ||
              formTemplateDetail.reportTypeId == 3
            ) {
              //dashboard report or xtra report
              var isDashboardReport = formTemplateDetail.reportTypeId == 3,
                isXtraReport = formTemplateDetail.reportTypeId == 2,
                firstParameterFormulaName =
                  formTemplateDetail.firstParameterFormulaName,
                secondParameterFormulaName =
                  formTemplateDetail.secondParameterFormulaName,
                firstParameterIsNull = String.isNullOrWhiteSpace(
                  firstParameterFormulaName
                ),
                secondParameterIsNull = String.isNullOrWhiteSpace(
                  secondParameterFormulaName
                );

              if (firstParameterIsNull && secondParameterIsNull) {
                if (isLightBox) {
                  modalFrameBlock.html(
                    String.format(
                      '<div class="alert alert-warning mb-0 fs-5">{0}</div>',
                      this.$t(
                        "BaseModelFields.FormTemplateParametersNullOrEmpty",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    )
                  );
                } else {
                  createToast(
                    this.$t(
                      "BaseModelFields.FormTemplateParametersNullOrEmpty",
                      {},
                      { locale: this.$store.state.activeLang }
                    ),
                    {
                      showIcon: true,
                      position: "top-right",
                      type: "warning",
                      transition: "zoom",
                    }
                  );
                }
                return;
              }

              var firstParamField = allFields.find(
                (f) => f.formulaName == firstParameterFormulaName
              );
              if (!firstParamField) {
                if (isLightBox) {
                  modalFrameBlock.html(
                    String.format(
                      '<div class="alert alert-warning mb-0 fs-5">{0}</div>',
                      this.$t(
                        "BaseModelFields.FormTemplateFieldNotFound",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    ).replaceAll(
                      "FIELD_FORMULA_NAME",
                      firstParameterFormulaName
                    )
                  );
                } else {
                  createToast(
                    this.$t(
                      "BaseModelFields.FormTemplateFieldNotFound",
                      {},
                      { locale: this.$store.state.activeLang }
                    ).replaceAll(
                      "FIELD_FORMULA_NAME",
                      firstParameterFormulaName
                    ),
                    {
                      showIcon: true,
                      position: "top-right",
                      type: "warning",
                      transition: "zoom",
                    }
                  );
                }
                return;
              }

              var secondParamField = allFields.find(
                (f) => f.formulaName == secondParameterFormulaName
              );
              if (!secondParameterIsNull && !secondParamField) {
                if (isLightBox) {
                  modalFrameBlock.html(
                    String.format(
                      '<div class="alert alert-warning mb-0 fs-5">{0}</div>',
                      this.$t(
                        "BaseModelFields.FormTemplateFieldNotFound",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    ).replaceAll(
                      "FIELD_FORMULA_NAME",
                      secondParameterFormulaName
                    )
                  );
                } else {
                  createToast(
                    this.$t(
                      "BaseModelFields.FormTemplateFieldNotFound",
                      {},
                      { locale: this.$store.state.activeLang }
                    ).replaceAll(
                      "FIELD_FORMULA_NAME",
                      secondParameterFormulaName
                    ),
                    {
                      showIcon: true,
                      position: "top-right",
                      type: "warning",
                      transition: "zoom",
                    }
                  );
                }
                return;
              }

              var firstParameterValue =
                  detail[
                    this.fieldTypes.select.includes(firstParamField.fieldType)
                      ? `${firstParameterFormulaName}${this.$fieldPublicIdFormulaExtension}`
                      : firstParameterFormulaName
                  ],
                secondParameterValue = !secondParameterIsNull
                  ? detail[
                      this.fieldTypes.select.includes(
                        secondParamField.fieldType
                      )
                        ? `${secondParameterFormulaName}${this.$fieldPublicIdFormulaExtension}`
                        : secondParameterFormulaName
                    ]
                  : "",
                parameterValues = [];

              parameterValues.push({
                formulaName: firstParameterFormulaName,
                value: String.isNullOrWhiteSpace(firstParameterValue)
                  ? null
                  : firstParameterValue,
              });

              if (!secondParameterIsNull) {
                parameterValues.push({
                  formulaName: secondParameterFormulaName,
                  value: String.isNullOrWhiteSpace(secondParameterValue)
                    ? null
                    : secondParameterValue,
                });
              }

              var valuesNotAvailable = parameterValues.filter(function (f) {
                return String.isNullOrWhiteSpace(f.value);
              });
              if (valuesNotAvailable.length > 0) {
                this.getRecordByFields(
                  this.$route.params.key,
                  detail["PUBLICID"],
                  valuesNotAvailable.map((m) => m.formulaName)
                )
                  .then((response) => {
                    if (response) {
                      parameterValues
                        .filter(function (f) {
                          return String.isNullOrWhiteSpace(f.value);
                        })
                        .forEach((parameterValue) => {
                          var valueObj = response[parameterValue.formulaName],
                            value = !String.isNullOrWhiteSpace(
                              valueObj.publicIdValue
                            )
                              ? valueObj.publicIdValue
                              : valueObj.value;
                          if (String.isNullOrWhiteSpace(value)) {
                            parameterValue.value = "";
                          } else {
                            parameterValue.value = value;
                          }
                        });

                      var reportingModel = {
                        url: isXtraReport
                          ? `/XtraReporting/Viewer/${formTemplateDetail.xtraReportPublicId}`
                          : `/DashboardReporting/Viewer/${formTemplateDetail.dashboardPublicId}`,
                        setXrmParameterOne:
                          parameterValues.length > 0
                            ? parameterValues[0].value
                            : "",
                        setXrmParameterTwo:
                          parameterValues.length > 1
                            ? parameterValues[1].value
                            : "",
                      };

                      this.createKeyForCurrentUser(reportingModel)
                        .then((response) => {
                          var result = response.data,
                            token = result.data;
                          if (!String.isNullOrWhiteSpace(token)) {
                            var url = `${this.getReportingWebSiteUrl()}/${
                              this.$setXRMReportAuthorizeActionUrl
                            }?key=${encodeURIComponent(token)}`;

                            if (isDirectForward) {
                              window.open(url, "_self");
                            } else if (isNewTabForward) {
                              window.open(url, "_blank");
                            } else if (isLightBox) {
                              this.createFrameAndCalculateHeight(
                                modalFrameBlock,
                                modal,
                                url
                              );
                            }
                          }
                        })
                        .catch((err) => {
                          //TODO: Error
                        });
                    }
                  })
                  .catch((err) => {
                    //TODO: Error
                  });
              } else {
                var reportingModel = {
                  url: isXtraReport
                    ? `/XtraReporting/Viewer/${formTemplateDetail.xtraReportPublicId}`
                    : `/DashboardReporting/Viewer/${formTemplateDetail.dashboardPublicId}`,
                  setXrmParameterOne:
                    parameterValues.length > 0 ? parameterValues[0].value : "",
                  setXrmParameterTwo:
                    parameterValues.length > 1 ? parameterValues[1].value : "",
                };

                this.createKeyForCurrentUser(reportingModel)
                  .then((response) => {
                    var result = response.data,
                      token = result.data;
                    if (!String.isNullOrWhiteSpace(token)) {
                      var url = `${this.getReportingWebSiteUrl()}/${
                        this.$setXRMReportAuthorizeActionUrl
                      }?key=${encodeURIComponent(token)}`;

                      if (isDirectForward) {
                        window.open(url, "_self");
                      } else if (isNewTabForward) {
                        window.open(url, "_blank");
                      } else if (isLightBox) {
                        this.createFrameAndCalculateHeight(
                          modalFrameBlock,
                          modal,
                          url
                        );
                      }
                    }
                  })
                  .catch((err) => {
                    //TODO: Error
                  });
              }
            }
          })
          .catch((err) => {
            //TODO: Error
          });
      }
    },
    //End SetXRM Custom Button Functions

    //Start Field Data Formating and Format Information Functions
    dateTimeFormat(value) {
      if (
        String.isNullOrWhiteSpace(value) ||
        value == "Invalid date" ||
        value == "-"
      )
        return value;

      var shortFormatInfo = this.getDateTimeFormat("short"),
        format = String.format(
          "{0} {1}",
          shortFormatInfo.calendar.toUpperCase(),
          shortFormatInfo.time
        );
      return this.$moment(value, format).format(format);
    },
    timeFormat(value) {
      if (
        String.isNullOrWhiteSpace(value) ||
        value == "Invalid date" ||
        value == "-"
      )
        return value;

      var shortFormatInfo = this.getDateTimeFormat("short"),
        format = shortFormatInfo.time;
      return this.$moment(value, format).format(format);
    },
    dateFormat(value) {
      if (
        String.isNullOrWhiteSpace(value) ||
        value == "Invalid date" ||
        value == "-"
      )
        return value;

      var formatInfo = this.getDateTimeFormat("short"),
        format = String.format("{0}", formatInfo.calendar.toUpperCase());
      return this.$moment(value, format).format(format);
    },
    getNumberFormat() {
      return this.$i18n.global.getNumberFormat(this.$store.state.activeLang)
        .currency;
    },
    getDateTimeFormat(type) {
      var d = this.$i18n.global.getDateTimeFormat(this.$store.state.activeLang);
      if (type === "short") {
        return d.short;
      }
      return d.long;
    },
    getTimeFormatByValue(value, formatInfo) {
      var valueFormatIsDateTime = value.length >= formatInfo.calendar.length,
        valueIsEng = value.includes("AM") || value.includes("PM"), //back-end fix
        valueFormat = formatInfo.calendar;

      if (valueFormatIsDateTime) {
        valueFormat = `${formatInfo.calendar}`;
      } else {
        valueFormat = `${formatInfo.time}`;
        if (valueIsEng) {
          valueFormat += " A";
        }
      }

      return valueFormat;
    },
    //End Field Data Formating and Format Information Functions

    //Start Set Pages(New,Detail,Edit,Calendar..) Functions
    elementScrollToTop(element, topValue = 0, animateType = "slow") {
      element.animate({ scrollTop: topValue }, animateType);
    },
    formValidation(form, values, allFields) {
      var pageLayoutRequiredFields = form.find(
          `[data-pagelayoutelementisrequired="true"]`
        ),
        validations = [];

      if (pageLayoutRequiredFields.length > 0) {
        pageLayoutRequiredFields.each(function (i, v) {
          var el = $(v),
            pageLayoutField = allFields.find(
              (f) => f.formulaName === el.data("formulaname")
            );

          if (!pageLayoutField) return;

          pageLayoutField.isRequired = true;
          pageLayoutField.isHiddenPL = el.hasClass("hidden");
        });
      }

      values.forEach((fieldValue) => {
        var valueField = allFields.find(
          (f) => f.formulaName === fieldValue.key
        );
        if (!valueField) return;

        var messageObj = {
          fieldName: valueField.name,
        };

        if (this.$store.state.isMultiLanguage) {
          var fieldLocalizationValue =
            this.$store.getters.getLocalizationValuesByParameters({
              parentId: valueField.customObjectPublicId,
              itemFormulaName: valueField.formulaName,
              itemTypeId: 2,
            });
          if (fieldLocalizationValue) {
            messageObj.fieldName = fieldLocalizationValue.value;
          }
        }

        if (
          valueField.isActive &&
          valueField.isRequired &&
          String.isNullOrWhiteSpace(fieldValue.value)
        ) {
          var message = this.$t(
            "FieldWarnings.FieldIsRequired",
            {},
            { locale: this.$store.state.activeLang }
          );
          if (valueField.isHiddenPL) {
            message += ` (${this.$t(
              "FieldWarnings.PageLayoutHiddenField",
              {},
              { locale: this.$store.state.activeLang }
            )})`;
          }
          validations.push({
            ...messageObj,
            message: message,
          });
        }

        if (
          !String.isNullOrWhiteSpace(fieldValue.value) &&
          valueField.fieldType === "Phone"
        ) {
          var intlInstance = this.getPhoneInputInstance(valueField.publicId);
          if (intlInstance) {
            if (!intlInstance.isValidNumber()) {
              validations.push({
                ...messageObj,
                message: this.$t(
                  "FieldWarnings.PhoneNotValid",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
              });
            }
          }
        }

        if (
          valueField.fieldType == "Email" &&
          !String.isNullOrWhiteSpace(fieldValue.value) &&
          !String.isEmail(fieldValue.value)
        ) {
          validations.push({
            ...messageObj,
            message: this.$t(
              "FieldWarnings.EmailNotValid",
              {},
              { locale: this.$store.state.activeLang }
            ),
          });
        }

        if (
          valueField.fieldType == "Text" &&
          valueField.length > 0 &&
          !String.isNullOrWhiteSpace(fieldValue.value) &&
          fieldValue.value.length > valueField.length
        ) {
          validations.push({
            ...messageObj,
            message: this.$t(
              "FieldWarnings.FieldLengthNotValid",
              {},
              { locale: this.$store.state.activeLang }
            ),
          });
        }
      });

      return validations;
    },
    setLoader(pageItem) {
      var inputType = $(pageItem).data("inputtype");
      if (inputType == "text" || inputType == "tel") {
        inputType = "input";
      }

      var element = $(pageItem).find(inputType);
      if (!element || element.length == 0) return;

      element
        .val("")
        .attr(
          "style",
          `${element.attr("style")} ${this.setXrmInputSpinnerGifSrc}`
        );
    },
    bodyCreateBlock(type, id, innerHTML) {
      if (
        String.isNullOrWhiteSpace(type) ||
        String.isNullOrWhiteSpace(id) ||
        String.isNullOrWhiteSpace(innerHTML) ||
        (!String.isNullOrWhiteSpace(type) &&
          !["script", "style", "html"].includes(type))
      )
        return;

      var block = document.createElement(type === "html" ? "div" : type),
        duplicateBlocks = $(`#${id}`);

      if (duplicateBlocks && duplicateBlocks.length > 0) {
        duplicateBlocks.each(function (i, duplicateBlock) {
          duplicateBlock.remove();
        });
      }

      block.id = id;
      block.innerHTML = innerHTML;
      document.body.append(block);
    },
    async getSetScripts(customObjectKey, viewName) {
      this.setScriptBodyKey = `${
        this.bodyCustomKeys.script
      }_${customObjectKey}_${
        String.isNullOrWhiteSpace(this.$route.query.filterId)
          ? "default"
          : this.$route.query.filterId
      }`;

      this.setScriptsForWorkAfterDrawLayout = [];

      var duplicateCode = $(`#${this.setScriptBodyKey}`);
      if (duplicateCode.length > 0) duplicateCode.remove();

      $(`[id*='${this.bodyCustomKeys.script}']`).remove();
      var request = this.$appAxios
        .get(
          `/rws-GetSetScriptList?customObjectKey=${customObjectKey}&viewName=${viewName}`
        )
        .then((response) => {
          var result = response.data;
          if (!String.isNullOrWhiteSpace(result)) {
            result.forEach((setScript, i) => {
              if (!String.isNullOrWhiteSpace(setScript.script)) {
                if (
                  setScript.isRunAfterPageLayoutLoaded &&
                  !this.$route.meta.isListPage
                ) {
                  this.setScriptsForWorkAfterDrawLayout.push({
                    ...setScript,
                    index: i,
                  });
                } else {
                  this.bodyCreateBlock(
                    "script",
                    `${this.setScriptBodyKey}_${i}`,
                    setScript.script
                  );
                }
              }
            });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });

      return await request;
    },
    operationAfterPageLoadForSetScripts() {
      if (this.setScriptsForWorkAfterDrawLayout.length == 0) return;

      this.setScriptsForWorkAfterDrawLayout.forEach((setScript) => {
        var scriptBlock = document.createElement("script");
        scriptBlock.id = `${this.setScriptBodyKey}_${setScript.index}_afterpagelayout`;
        scriptBlock.innerHTML = setScript.script;
        document.body.append(scriptBlock);
      });
    },

    getFileSize(file) {
      if (!file) return `0 KB`;

      if (file.size.toString().length < 7) {
        return `${Math.round(+file.size / 1024).toFixed(2)} KB`;
      }

      return `${(Math.round(+file.size / 1024) / 1000).toFixed(2)} MB`;
    },
    modelCreate(formId) {
      var form = $(formId),
        ckeditors = window.CKEDITOR.instances,
        self = this;

      var model = {
        customObjectPublicId: $("#CustomObjectPublicId").val(),
        customObjectKey: $("#CustomObjectKey").val(),
        pageLayoutId: $("#LayoutPublicId").val(),
        recordPublicId: $("#RecordPublicId").val(),
        layout: {
          formulaName: $("#LayoutFormulaName").val(),
          publicId: $("#LayoutPublicId").val(),
        },
      };

      var headerValues = [];
      form.find(".page-item:not(.edit-cell-page-item)").each(function (i, v) {
        var pageItem = $(v),
          formulaName = pageItem.data("formulaname"),
          publicId = pageItem.data("publicid"),
          inputType = pageItem.data("inputtype"),
          fieldType = pageItem.data("fieldtype"),
          isLiveLookup = pageItem.data("islivelookup"),
          isCalculatedOnlyOnInsert = pageItem.data("iscalculatedonlyoninsert"),
          value = "",
          oldValue = "",
          text = "",
          oldText = "",
          isStringValue =
            fieldType.includes("Formula") ||
            inputType == "text" ||
            inputType == "tel" ||
            inputType == "email" ||
            inputType == "url";

        if (
          String.isNullOrWhiteSpace(formulaName) ||
          fieldType.includes("Formula") ||
          fieldType.includes("Rollup")
        ) {
          return;
        }

        if (isStringValue) {
          value = pageItem.find(`input#${publicId}`).val();
          oldValue = pageItem.find(`input#${publicId}`).attr("data-oldvalue");

          if (fieldType == "Phone") {
            var phoneInput = $(`[name="${publicId}_full_phone_number"]`);
            if (phoneInput) {
              value = phoneInput.val();
            }

            if (self.$route.meta.isNewPage) {
              oldValue = value;
            }
          }
        } else if (fieldType === "TextArea") {
          value = pageItem.find("textarea").val();
          oldValue = pageItem.find("textarea").attr("data-oldvalue");
        } else if (inputType === "checkbox") {
          value = pageItem.find("input:checkbox").is(":checked");
          oldValue = pageItem.find("input:checkbox").attr("data-oldvalue");
        } else if (inputType === "file") {
          value = $("#" + pageItem.data("publicid")).val();
          oldValue = $("#" + pageItem.data("publicid")).attr("data-oldvalue");
        } else if (fieldType === "Html") {
          value = ckeditors[pageItem.data("publicid")].getData();
          text = ckeditors[pageItem.data("publicid")].editable().getText();
          oldValue = $("#" + pageItem.data("publicid")).attr("data-oldvalue");
        } else if (inputType === "select") {
          if (pageItem.data("isradio")) {
            oldValue = pageItem.find(".radio-select-val").data("oldvalue");
            if (pageItem.data("ismultiplevalue")) {
              var checkeds = $(
                String.format("[name={0}]:checked", pageItem.data("publicid"))
              );
              if (checkeds.length > 0) {
                var values = [];
                checkeds.each((i, v) => {
                  values.push($(v).val());
                });
                value = values.join(self.$systemSeparator);
              }
            } else {
              value = $(
                String.format("[name={0}]:checked", pageItem.data("publicid"))
              ).val();
            }
            value = String.isNullOrWhiteSpace(value) ? "" : value;
          } else {
            var selectedData = pageItem.find("select").select2("data"),
              isMultiple = pageItem.data("ismultiplevalue");

            oldValue = pageItem.find("select").attr("data-oldvalue");
            oldText = pageItem
              .find("#SELECT-TEXT-" + publicId)
              .attr("data-oldvalue");

            if (selectedData.length > 0) {
              if (isMultiple) {
                selectedData.forEach(function (v, i) {
                  if (selectedData.length - 1 == i) {
                    value += v.id;
                    text += v.text;
                  } else {
                    value += v.id + self.$systemSeparator;
                    text += v.text + self.$systemSeparator;
                  }
                });
              } else {
                value = selectedData[0].id;
                text = selectedData[0].text;
              }
            }
          }
        }

        var itemObject = {
          fieldType: fieldType,
          isLiveLookup: String.isNullOrWhiteSpace(isLiveLookup)
            ? false
            : isLiveLookup,
          isCalculatedOnlyOnInsert: String.isNullOrWhiteSpace(
            isCalculatedOnlyOnInsert
          )
            ? false
            : isCalculatedOnlyOnInsert,
          key: formulaName,
          value: value.toString(),
          oldValue: oldValue.toString(),
          text: text,
          oldText: oldText,
        };

        headerValues.push(itemObject);
      });

      var tableValues = [];
      form.find(".jsonData").each(function (i, v) {
        var value = JSON.parse($(v).val()),
          values = value.values;
        values.forEach((element, i) => {
          var dataValues = [];
          $.each(element, function (key, value) {
            var data = dataValues.find((f) => f.key == key);
            if (
              !String.isNullOrWhiteSpace(data) ||
              key.includes(self.$fieldPublicIdFormulaExtension.replace("_", ""))
            )
              return;

            if (element[key + self.$fieldPublicIdFormulaExtension]) {
              dataValues.push({
                key: key,
                value: element[key + self.$fieldPublicIdFormulaExtension],
              });
            } else {
              dataValues.push({ key: key, value: value });
            }
          });

          //TODO: #1549 fix - for default values
          dataValues = dataValues.filter(
            (f) => !String.isNullOrWhiteSpace(f.value)
          );

          tableValues.push({
            customObjectKey: value.customObjectKey,
            lookupObjectFormulaName: value.lookupObjectFormulaName,
            lookupRelationPublicId: value.lookupRelationPublicId,
            // orderNumber: ++i,
            recordPublicId: element.PUBLICID,
            values: dataValues,
          });
        });
      });

      model.tableValues = tableValues;
      model.values = headerValues;

      return model;
    },
    checkUnSavedData(formId) {
      var model = this.modelCreate(formId);
      model.values = model.values.filter(function (f) {
        return f.fieldType.includes("Phone")
          ? f.value.trim().replaceAll(" ", "") !==
              f.oldValue.trim().replaceAll(" ", "")
          : f.value.trim() !== f.oldValue.trim() &&
              !f.oldValue.includes("error");
      });

      return model.values.length > 0;
    },
    forceFormValidation($form) {
      var valid = true;
      this.invalidClasses.forEach((element) => {
        if ($form.find(element).length > 0) {
          valid = false;
        }
      });
      return valid;
    },
    getValidationMessages(
      validations,
      isShowMessageInModal,
      type,
      allFields,
      ignoreIsShowMessageInModalParameter = false
    ) {
      validations
        .filter((f) => !String.isNullOrWhiteSpace(f.fieldPublicId))
        .forEach((validation) => {
          var validationField = allFields.find(
            (f) => f.publicId === validation.fieldPublicId
          );
          if (validationField) {
            if (this.$store.state.isMultiLanguage) {
              var fieldLocalizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  parentId: validationField.customObjectPublicId,
                  itemFormulaName: validationField.formulaName,
                  itemTypeId: 2,
                });
              if (fieldLocalizationValue) {
                validation.fieldName = fieldLocalizationValue.value;
              } else {
                validation.fieldName = validationField.name;
              }
            } else {
              validation.fieldName = validationField.name;
            }
          }
        });

      if (ignoreIsShowMessageInModalParameter) {
        return validations.filter((f) => f.validationType["value"] == type);
      }

      return validations.filter(
        (f) =>
          f.isShowMessageInModal == isShowMessageInModal &&
          f.validationType["value"] == type
      );
    },
    getPageItemFormValue(pageItem, isReturnOldValue) {
      if (!pageItem) return { value: "", text: "", isLiveLookup: false };

      var self = this,
        ckeditors = window.CKEDITOR.instances,
        formulaName = pageItem.data("formulaname"),
        publicId = pageItem.data("publicid"),
        inputType = pageItem.data("inputtype"),
        fieldType = pageItem.data("fieldtype"),
        isLiveLookup = pageItem.data("islivelookup"),
        value = "",
        oldValue = "",
        text = "",
        oldText = "",
        isStringValue =
          fieldType.includes("Formula") ||
          inputType == "text" ||
          inputType == "tel" ||
          inputType == "email" ||
          inputType == "url";

      if (isStringValue) {
        value = pageItem.find(`input#${publicId}`).val();
        text = value;
        oldValue = pageItem.find(`input#${publicId}`).attr("data-oldvalue");

        if (fieldType == "Phone") {
          var phoneInput = $(`[name="${publicId}_full_phone_number"]`);
          if (phoneInput) {
            value = phoneInput.val();
          }
          oldValue = value;
        }
      } else if (fieldType === "TextArea") {
        value = pageItem.find("textarea").val();
        text = value;
        oldValue = pageItem.find("textarea").attr("data-oldvalue");
      } else if (inputType === "checkbox") {
        value = pageItem.find("input:checkbox").is(":checked");
        text = value;
        oldValue = pageItem.find("input:checkbox").attr("data-oldvalue");
      } else if (inputType === "file") {
        value = $("#" + pageItem.data("publicid")).val();
        text = $("#FILENAME-" + pageItem.data("publicid")).val();
        oldValue = $("#" + pageItem.data("publicid")).attr("data-oldvalue");
        oldText = $("#FILENAME-" + pageItem.data("publicid")).attr(
          "data-oldvalue"
        );
      } else if (fieldType === "Html") {
        value = ckeditors[pageItem.data("publicid")].getData();
        text = value;
        oldValue = $("#" + pageItem.data("publicid")).attr("data-oldvalue");
      } else if (inputType === "select") {
        if (pageItem.data("isradio")) {
          var radioSelectlistOldBlock = $(
            String.format("[name={0}]:first", pageItem.data("publicid"))
          ).closest("div");
          oldValue = radioSelectlistOldBlock.attr("data-oldvalue");
          oldText = radioSelectlistOldBlock.attr("data-oldtext");
          if (pageItem.data("ismultiplevalue")) {
            var checkeds = $(
              String.format("[name={0}]:checked", pageItem.data("publicid"))
            );
            if (checkeds.length > 0) {
              var values = [],
                texts = [];
              checkeds.each((i, v) => {
                values.push($(v).val());
                texts.push($("[for=" + $(v).attr("id") + "]").text());
              });
              value = values.join(self.$systemSeparator);
              text = texts.join(self.$systemSeparator);
            }
          } else {
            value = $(
              String.format("[name={0}]:checked", pageItem.data("publicid"))
            ).val();
            text = $(
              "[for=" +
                $(
                  String.format("[name={0}]:checked", pageItem.data("publicid"))
                ).attr("id") +
                "]"
            ).text();
          }
          value = String.isNullOrWhiteSpace(value) ? "" : value;
          text = String.isNullOrWhiteSpace(text) ? "" : text;
        } else {
          var selectedData = pageItem.find("select").select2("data"),
            isMultiple = pageItem.data("ismultiplevalue");

          oldValue = pageItem.find("select").attr("data-oldvalue");
          oldText = pageItem.find("input").attr("data-oldvalue");

          if (selectedData.length > 0) {
            if (isMultiple) {
              selectedData.forEach(function (v, i) {
                if (selectedData.length - 1 == i) {
                  value += v.id;
                  text += v.text;
                } else {
                  value += v.id + self.$systemSeparator;
                  text += v.text + self.$systemSeparator;
                }
              });
            } else {
              value = selectedData[0].id;
              text = selectedData[0].text;
            }
          }
        }
      }

      return isReturnOldValue
        ? {
            value: oldValue,
            text: oldText,
            isLiveLookup: String.isNullOrWhiteSpace(isLiveLookup)
              ? false
              : isLiveLookup,
          }
        : {
            value: value,
            text: text,
            isLiveLookup: String.isNullOrWhiteSpace(isLiveLookup)
              ? false
              : isLiveLookup,
          };
    },
    getFieldFormulaName(fields, publicId) {
      var field = fields.find((f) => f.publicId == publicId);
      if (String.isNullOrWhiteSpace(field)) return null;

      return field.fieldModel.formulaName;
    },
    calendarDataChange(
      data,
      calendar,
      fields,
      isReturnCalendarResources = false
    ) {
      var values = [],
        events = [],
        calendarResources = [],
        self = this,
        shortFormatInfo = this.getDateTimeFormat("short"),
        format = `${shortFormatInfo.calendar.toUpperCase()} ${
          shortFormatInfo.time
        }`;

      data.forEach((element) => {
        values.push(element.fieldValues);
      });

      values.forEach((event, i) => {
        var eventTitles = calendar.titleFieldPublicIds.split(
          this.$systemSeparator
        );
        var eventTitlesFormulaNames = [];
        eventTitles.forEach((element) => {
          eventTitlesFormulaNames.push(
            self.getFieldFormulaName(fields, element)
          );
        });
        var startDate =
            event[
              self.getFieldFormulaName(fields, calendar.startDateFieldPublicId)
            ],
          endDate =
            event[
              self.getFieldFormulaName(fields, calendar.endDateFieldPublicId)
            ],
          titleField = Object.keys(event)
            .filter((key) => eventTitlesFormulaNames.includes(key))
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: event[key] });
            }, {}),
          resourceId = "";

        var sortTitleItems = [];
        eventTitlesFormulaNames.forEach((formulaName) => {
          sortTitleItems[
            eventTitlesFormulaNames.findIndex((f) => f == formulaName)
          ] = titleField[formulaName];
        });

        if (!String.isNullOrWhiteSpace(calendar.timelineFieldPublicId)) {
          var timelineField =
            event[
              self.getFieldFormulaName(fields, calendar.timelineFieldPublicId)
            ];

          if (!String.isNullOrWhiteSpace(timelineField)) {
            resourceId = timelineField.publicIdValue;
            if (
              !String.isNullOrWhiteSpace(resourceId) &&
              String.isNullOrWhiteSpace(
                calendarResources.find((x) => x.id == resourceId)
              )
            ) {
              calendarResources.push({
                id: resourceId,
                title: timelineField.value,
              });
            }
          }
        }

        if (String.isNullOrWhiteSpace(startDate) || $.isEmptyObject(titleField))
          return;

        if (String.isNullOrWhiteSpace(startDate.value)) return;

        var start = startDate.value.replace(/T.*$/, "");
        var end = endDate.value.replace(/T.*$/, "");

        var recordPublicId = event["PUBLICID"].value,
          title = sortTitleItems
            .filter((f) => !String.isNullOrWhiteSpace(f))
            .map((u) => u.value)
            .filter((f) => !String.isNullOrWhiteSpace(f))
            .join(", "),
          colorFieldValue = event[calendar.colorCodeFieldFormulaName];

        if (!String.isNullOrWhiteSpace(colorFieldValue)) {
          colorFieldValue = colorFieldValue.value;
        } else {
          colorFieldValue = null;
        }

        var fcEvent = {
          id: recordPublicId,
          resourceId: resourceId,
          start: self.$moment(start, format).format(),
          end: self.$moment(end, format).format(),
          allDay: String.isNullOrWhiteSpace(end),
          backgroundColor: colorFieldValue,
          borderColor: colorFieldValue,
          title: title,
          // classNames: ["text-wrap"],
          // url: `/set/detail/${self.$route.params.key}/${recordPublicId}`,
        };
        events.push(fcEvent);
      });

      return isReturnCalendarResources ? calendarResources : events;
    },
    getChildFieldPublicIds(parentId) {
      var $loopRepeat = true,
        childRelationFieldPublicIds = [],
        loopFieldPublicIds = [parentId];

      while ($loopRepeat) {
        loopFieldPublicIds.forEach((element) => {
          var childs = $(
            String.format("[data-controllingpublicid='{0}']", element)
          );
          if (childs.length > 0) {
            var z = 0;
            $.each(childs, function (o, g) {
              var childEl = $(g),
                childPublicId = childEl.data("publicid"),
                childIsElementControllingField = !String.isNullOrWhiteSpace(
                  childEl.data("controllingpublicid")
                );

              childRelationFieldPublicIds.push(childPublicId);
              if (childIsElementControllingField) {
                if (z == 0) loopFieldPublicIds = [];
                z++;

                loopFieldPublicIds.push(childPublicId);
                $loopRepeat = true;
              } else {
                $loopRepeat = false;
              }
            });
          } else {
            $loopRepeat = false;
          }
        });
      }

      return childRelationFieldPublicIds;
    },
    getChildFieldPublicIdsForCalculateTriggerField(parentId) {
      var $loopRepeat = true,
        childRelationFieldPublicIds = [],
        loopFieldPublicIds = [parentId];

      while ($loopRepeat) {
        loopFieldPublicIds.forEach((element) => {
          var childs = $(
            String.format(
              "[data-calculatetriggerfieldpublicids*='{0}']",
              element
            )
          );
          if (childs.length > 0) {
            var z = 0;
            $.each(childs, function (o, g) {
              var childEl = $(g),
                childPublicId = childEl.data("publicid"),
                childIsElementCalculateField = !String.isNullOrWhiteSpace(
                  childEl.data("calculatetriggerfieldpublicids")
                );

              childRelationFieldPublicIds.push(childPublicId);
              if (childIsElementCalculateField) {
                if (z == 0) loopFieldPublicIds = [];
                z++;

                loopFieldPublicIds.push(childPublicId);
                $loopRepeat = true;
              } else {
                $loopRepeat = false;
              }
            });
          } else {
            $loopRepeat = false;
          }
        });
      }

      return childRelationFieldPublicIds;
    },
    childFieldSelectlistSetNullValue(childRelationFieldPublicIds) {
      if (childRelationFieldPublicIds.length > 0) {
        var self = this;
        childRelationFieldPublicIds.forEach((element) => {
          var $el = $(String.format("#{0}", element));
          if ($el.length == 0) return;

          var pageItem = $(String.format("[data-publicid='{0}']", element)),
            fieldType = pageItem.data("fieldtype"),
            isRequired = pageItem.data("isrequired"),
            isMultiple = pageItem.data("ismultiplevalue"),
            isCalcSystem =
              fieldType.includes("Formula") || fieldType == "AutoId"
                ? true
                : false;

          $el.val(null);
          $el.select2({
            language: this.getSelect2Locale(this.$store.state.activeLang),
            theme: "bootstrap-5",
            disabled: true,
            multiple: isMultiple,
            placeholder: isCalcSystem
              ? this.$t(
                  "BaseModelFields.FieldIsCalcForSystem",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              : this.$t(
                  "BaseModelFields.Choose",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
          });
          $el
            .parents(".page-item")
            .find('[id*="open_detail"]')
            .addClass("hidden");

          //TODO: Vue components value
          //vue component value
          // var vElement = document.getElementById(element),
          //   event = new UIEvent("select", {
          //     bubbles: true,
          //     cancelable: true,
          //   });
          // vElement.dispatchEvent(event);
        });
      }
    },
    childValuesEmpty(publicId) {
      var childs = $(
          String.format(
            "[data-calculatetriggerfieldpublicids*='{0}']",
            publicId
          )
        ),
        self = this;
      if (childs.length === 0) return;

      childs.each(function (i, pageItem) {
        pageItem = $(pageItem);
        var formElementType = pageItem.data("inputtype"),
          isTelInput = formElementType == "tel",
          pageItemPublicId = pageItem.data("publicid");
        if (formElementType == "text" || isTelInput) {
          formElementType = "input";
        }
        var formElements = pageItem.find(formElementType);
        if (formElements.length == 0) return true;

        formElements.each(function (i, element) {
          var item = $(element);

          item.val("");
          if (isTelInput) {
            $(`[name="${pageItemPublicId}_full_phone_number"]`).val("");
          }

          var options = item.find("option");
          if (options.length > 0) {
            options.remove();

            //vue component value
            var vElement = document.getElementById(pageItemPublicId),
              event = new UIEvent("select", {
                bubbles: true,
                cancelable: true,
              });
            vElement.dispatchEvent(event);
          }
        });
      });
    },
    getPhoneInputInstance(elementId) {
      var intlInstance = null,
        intlTelInputGlobals = window.intlTelInputGlobals,
        intlInput = document.getElementById(elementId);
      if (intlTelInputGlobals && intlInput) {
        intlInstance = intlTelInputGlobals.getInstance(intlInput);
      }

      return intlInstance;
    },
    getFieldDefaultValue(self, calculatedFieldPublicId, parameters) {
      var pageItem = $(
        String.format("[data-publicid={0}]", calculatedFieldPublicId)
      );
      this.setLoader(pageItem);

      var pageLayoutFormulaName = $(
        ".system-hidden-field[id='LayoutFormulaName']"
      ).val();
      if (!String.isNullOrWhiteSpace(pageLayoutFormulaName)) {
        parameters["pageLayoutFormulaName"] = pageLayoutFormulaName;
      }

      this.$appAxios
        .post("/rws-SetController-GetFieldDefaultValue", parameters)
        .then((r) => {
          var result = r.data;
          var fieldDefaultValue = result.value;

          var formElementType = pageItem.data("inputtype"),
            formElementFieldType = pageItem.data("fieldtype");
          if (
            formElementType == "text" ||
            formElementType == "tel" ||
            formElementFieldType == "FormulaString"
          ) {
            formElementType = "input";
          }
          var formElement = pageItem.find(formElementType);
          if (formElement.length == 0) return true;

          formElement.css("background", "");

          if (result.isOk) {
            if (fieldDefaultValue.includes("error")) return;

            switch (formElementFieldType) {
              case "Html":
                window.CKEDITOR.instances[pageItem.data("publicid")].setData(
                  fieldDefaultValue
                );
                break;
              case "DateTime":
              case "FormulaDateTime":
                formElement.val(self.$root.dateTimeFormat(fieldDefaultValue));
                break;
              case "Date":
              case "FormulaDate":
                formElement.val(self.$root.dateFormat(fieldDefaultValue));
                break;
              case "Time":
                formElement.val(fieldDefaultValue);
                break;
              case "Phone":
                var intlInstance = self.$root.getPhoneInputInstance(
                  calculatedFieldPublicId
                );
                if (intlInstance) {
                  intlInstance.setNumber(fieldDefaultValue);
                }
                break;
              case "Lookup":
              case "SelectList":
                var ids = "",
                  texts = "";
                if (!String.isNullOrWhiteSpace(fieldDefaultValue)) {
                  var separator = fieldDefaultValue.includes(
                    self.$selectSeparator
                  )
                    ? self.$selectSeparator
                    : self.$systemSeparator;
                  ids = fieldDefaultValue.split(separator)[0];
                  texts = fieldDefaultValue.split(separator)[1];
                  self.$root.select2SetValue(
                    formElement,
                    pageItem.data("ismultiplevalue"),
                    ids,
                    texts
                  );
                }

                var element = document.getElementById(calculatedFieldPublicId);
                var event = new UIEvent("select", {
                  bubbles: true,
                  cancelable: true,
                });
                element.dispatchEvent(event);
                break;
              case "FormulaOrganizationalUnit":
                var formulaIds = "",
                  formulaTexts = "";
                if (!String.isNullOrWhiteSpace(fieldDefaultValue)) {
                  var formulaSeparator = fieldDefaultValue.includes(
                    self.$selectSeparator
                  )
                    ? self.$selectSeparator
                    : self.$systemSeparator;
                  formulaIds = fieldDefaultValue.split(formulaSeparator)[0];
                  formulaTexts = fieldDefaultValue.split(formulaSeparator)[1];
                  self.$root.select2SetValue(
                    formElement,
                    pageItem.data("ismultiplevalue"),
                    formulaIds,
                    formulaTexts
                  );
                }

                element = document.getElementById(calculatedFieldPublicId);
                event = new UIEvent("select", {
                  bubbles: true,
                  cancelable: true,
                });
                element.dispatchEvent(event);
                break;
              default:
                formElement.val(fieldDefaultValue).trigger("change");
                break;
            }

            //cell update operation detail page block
            if (self.$route.meta.isDetailPage) {
              var span = pageItem.find(
                  "span[id=" + pageItem.data("publicid") + "]"
                ),
                cards = $(".card");

              span.text(fieldDefaultValue);
              cards.addClass("z-index-0");
              pageItem.addClass("cell-updated-in-detail-page");

              setTimeout(() => {
                cards.removeClass("z-index-0");
                pageItem.removeClass("cell-updated-in-detail-page");
              }, 2000);
            }

            self.newChangeEvent(formElement.attr("id"));
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    newChangeEvent(fieldPublicId) {
      var childs = $(
        String.format(
          "[data-calculatetriggerfieldpublicids*='{0}']",
          fieldPublicId
        )
      );
      if (childs.length === 0) return;

      this.formItemNewChangeEvent([fieldPublicId]);
    },
    getSelect2ParentDropdown() {
      if (this.$route.meta.isCalendarPage) {
        return $(
          this.select2DropdownParents.find((f) => f.id == "calendar").parentId
        );
      } else if (this.$route.meta.isTreePage) {
        return $(
          this.select2DropdownParents.find((f) => f.id == "tree").parentId
        );
      }
      return null;
    },
    select2PagedSelectlistSelection(field, id) {
      var parentField = $("#" + field.publicId),
        childPageItems = $(
          String.format("[data-controllingpublicid={0}]", field.publicId)
        ),
        self = this;

      if (childPageItems.length == 0) return;

      //Relation child elements
      var childRelationFieldPublicIds = self.$root.getChildFieldPublicIds(
        field.publicId
      );
      self.$root.childFieldSelectlistSetNullValue(childRelationFieldPublicIds);

      $.each(childPageItems, function (i, el) {
        var element = $(el),
          controllingFieldPublicId = element.data("publicid"),
          childField = $("#" + controllingFieldPublicId);

        //Relation parent element
        childField.val(null);
        if (String.isNullOrWhiteSpace(parentField.val())) {
          childField.select2({
            language: self.$root.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            disabled: true,
            dropdownAutoWidth: false,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
          });
        } else {
          var ajaxUrl = null;
          switch (field.fieldType) {
            case "Predefined":
              ajaxUrl = self.getFieldRequestUrl("dependentPredefined", {
                id: id,
                predefinedDependencyPublicId: element.data(
                  "predefineddependencypublicid"
                ),
                controllingRecordId: field.predefinedPublicId,
              });
              break;
            case "OrganizationalUnit":
              ajaxUrl = self.getFieldRequestUrl("organizationalunit", {
                publicId: element.data("publicid"),
                name: element.data("organizationalunitname"),
                filterType: element.data("organizationalunitfiltertype"),
                groupIds: id,
                includeItself: element.data("organizationalunitincludeitself"),
                depth: element.data("organizationalunitdepth"),
                isAddCurrentKeys: false,
              });
              break;
            case "SelectList":
              ajaxUrl = self.getFieldRequestUrl("dependentSelect", {
                controllingRecordId: id,
                parentId: field.publicId,
              });
              break;
            case "Lookup":
              ajaxUrl = self.getFieldRequestUrl("lookup", {
                coId: field.customObjectPublicId,
                fieldPublicId: element.data("publicid"),
                controllingRecordId: id,
              });
              break;
            default:
              break;
          }

          if (String.isNullOrWhiteSpace(ajaxUrl)) {
            // console.error("select2PagedSelectlistSelection Ajax Url missing");
            return;
          }

          var elementIsDisabledValue = element.data("isdisabled"),
            isDisabled = String.isNullOrWhiteSpace(elementIsDisabledValue)
              ? false
              : Boolean.toBool(elementIsDisabledValue.toString());
          childField.select2({
            language: self.$root.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            allowClear: !isDisabled,
            dropdownParent: self.$root.getSelect2ParentDropdown(),
            maximumSelectionSize: 99,
            minimumInputLength: 0,
            dropdownAutoWidth: false,
            closeOnSelect: true,
            disabled: isDisabled,
            multiple: element.data("ismultiplevalue"),
            separator: self.$systemSeparator,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
            ajax: self.$root.getSelect2AjaxConfiguration(field, ajaxUrl),
            templateResult: function (d) {
              return Object.isHTML(d.text) ? $(d.text) : d.text;
            },
          });
        }
      });
    },
    select2PagedSelectlistDependencyChildTrigger(
      field,
      childMountedTriggered = false
    ) {
      // debugger;
      var self = this,
        parentDiv = $(
          String.format("[data-publicid='{0}']", field.controllingPublicId)
        );

      var parentField = $("#" + field.controllingPublicId),
        childPageItems = $(
          String.format(
            "[data-controllingpublicid={0}]",
            field.controllingPublicId
          )
        );

      //|| String.isNullOrWhiteSpace(parentField.val())
      if (childPageItems.length == 0) return;

      $.each(childPageItems, function (i, el) {
        var element = $(el),
          controllingFieldPublicId = element.data("publicid"),
          childField = $("#" + controllingFieldPublicId);

        if (!self.$route.meta.isEditPage && !childMountedTriggered) {
          //Relation child elements
          var childRelationFieldPublicIds = self.$root.getChildFieldPublicIds(
            controllingFieldPublicId
          );
          self.$root.childFieldSelectlistSetNullValue(
            childRelationFieldPublicIds
          );
          childField.val("");
        }

        if (String.isNullOrWhiteSpace(parentField.val())) {
          childField.select2({
            language: self.$root.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            disabled: true,
            dropdownAutoWidth: false,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
          });
        } else {
          var parentValue = parentField.val();
          if (Array.isArray(parentValue)) {
            parentValue = parentValue.join(self.$systemSeparator);
          }

          var ajaxUrl = null;
          switch (field.fieldType) {
            case "Predefined":
              ajaxUrl = self.getFieldRequestUrl("dependentPredefined", {
                id: parentValue,
                predefinedDependencyPublicId: element.data(
                  "predefineddependencypublicid"
                ),
                controllingRecordId: parentDiv.data("predefinedpublicid"),
              });
              break;
            case "OrganizationalUnit":
              ajaxUrl = self.getFieldRequestUrl("organizationalunit", {
                publicId: element.data("publicid"),
                name: element.data("organizationalunitname"),
                filterType: element.data("organizationalunitfiltertype"),
                groupIds: parentValue,
                includeItself: element.data("organizationalunitincludeitself"),
                depth: element.data("organizationalunitdepth"),
                isAddCurrentKeys: false,
              });
              break;
            case "SelectList":
              ajaxUrl = self.getFieldRequestUrl("dependentSelect", {
                controllingRecordId: parentValue,
                parentId: field.publicId,
              });
              break;
            case "Lookup":
              ajaxUrl = self.getFieldRequestUrl("lookup", {
                coId: field.customObjectPublicId,
                fieldPublicId: element.data("publicid"),
                controllingRecordId: parentValue,
              });
              break;
            default:
              break;
          }

          if (String.isNullOrWhiteSpace(ajaxUrl)) {
            // console.error(
            //   "select2PagedSelectlistDependencyChildTrigger Ajax Url missing"
            // );
            return;
          }

          var elementIsDisabledValue = element.data("isdisabled"),
            isDisabled = String.isNullOrWhiteSpace(elementIsDisabledValue)
              ? false
              : Boolean.toBool(elementIsDisabledValue.toString()),
            isMultiple = element.data("ismultiplevalue");
          childField.select2({
            dropdownParent: self.$root.getSelect2ParentDropdown(),
            language: self.$root.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            allowClear: !isDisabled,
            minimumInputLength: 0,
            maximumSelectionSize: 99,
            dropdownAutoWidth: false,
            closeOnSelect: true,
            disabled: isDisabled,
            multiple: isMultiple,
            separator: self.$systemSeparator,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
            ajax: self.$root.getSelect2AjaxConfiguration(field, ajaxUrl),
            templateResult: function (d) {
              return Object.isHTML(d.text) ? $(d.text) : d.text;
            },
          });
        }
      });
    },
    select2PagedSelectlistDependencyChildTriggerWithCellEdit(
      field,
      parentField,
      parentValue
    ) {
      var self = this,
        childPageItems = $(
          `[data-controllingpublicid=${field.controllingPublicId}]`
        );

      if (childPageItems.length == 0) return;

      $.each(childPageItems, function (i, el) {
        var element = $(el),
          controllingFieldPublicId = element.data("publicid"),
          childField = $("#" + controllingFieldPublicId);

        if (!self.$route.meta.isEditPage) {
          //Relation child elements
          var childRelationFieldPublicIds = self.$root.getChildFieldPublicIds(
            controllingFieldPublicId
          );
          self.$root.childFieldSelectlistSetNullValue(
            childRelationFieldPublicIds
          );
          childField.val("");
        }

        if (String.isNullOrWhiteSpace(parentValue)) {
          childField.select2({
            language: self.$root.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            disabled: true,
            dropdownAutoWidth: false,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
          });
        } else {
          var ajaxUrl = null;
          switch (field.fieldType) {
            case "Predefined":
              ajaxUrl = self.getFieldRequestUrl("dependentPredefined", {
                id: parentValue,
                predefinedDependencyPublicId: element.data(
                  "predefineddependencypublicid"
                ),
                controllingRecordId: parentField.predefinedPublicId,
              });
              break;
            case "OrganizationalUnit":
              ajaxUrl = self.getFieldRequestUrl("organizationalunit", {
                publicId: element.data("publicid"),
                name: element.data("organizationalunitname"),
                filterType: element.data("organizationalunitfiltertype"),
                groupIds: parentValue,
                includeItself: element.data("organizationalunitincludeitself"),
                depth: element.data("organizationalunitdepth"),
                isAddCurrentKeys: false,
              });
              break;
            case "SelectList":
              ajaxUrl = self.getFieldRequestUrl("dependentSelect", {
                controllingRecordId: parentValue,
                parentId: field.publicId,
              });
              break;
            case "Lookup":
              ajaxUrl = self.getFieldRequestUrl("lookup", {
                coId: field.customObjectPublicId,
                fieldPublicId: element.data("publicid"),
                controllingRecordId: parentValue,
              });
              break;
            default:
              break;
          }

          if (String.isNullOrWhiteSpace(ajaxUrl)) {
            // console.error(
            //   "select2PagedSelectlistDependencyChildTriggerWithCellEdit Ajax Url missing"
            // );
            return;
          }

          childField.select2({
            dropdownParent: self.$root.getSelect2ParentDropdown(),
            language: self.$root.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            allowClear: true,
            // closeOnSelect: element.data("ismultiplevalue") ? false : true,
            minimumInputLength: 0,
            maximumSelectionSize: 99,
            dropdownAutoWidth: false,
            closeOnSelect: true,
            disabled: false,
            multiple: element.data("ismultiplevalue"),
            separator: self.$systemSeparator,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
            ajax: self.$root.getSelect2AjaxConfiguration(field, ajaxUrl),
          });
        }
      });
    },
    calculateTriggerField(formulaField) {
      if (!formulaField.isActive) return;

      var childs = $(
        `[data-calculatetriggerfieldpublicids*='${formulaField.publicId}']`
      );
      if (childs.length === 0) return;

      var self = this,
        element = $(`#${formulaField.publicId}`),
        formId = "#newForm";

      if (self.$route.meta.isEditPage) {
        formId = "#editForm";
      } else if (this.$route.meta.isDetailPage) {
        formId = ".detail-edit-forms";
      }

      $(`${self.$systemFieldPrefix}`).each(function () {
        var triggerFieldPublicIds = $(this).data(
            "calculatetriggerfieldpublicids"
          ),
          isCalculateOnClientChange = $(this).data("iscalculateonclientchange"),
          isCalculatedOnlyOnInsert = $(this).data("iscalculatedonlyoninsert"),
          isSkipField =
            (self.$route.meta.isEditPage || self.$route.meta.isDetailPage) &&
            isCalculatedOnlyOnInsert;
        if (
          isCalculateOnClientChange &&
          !String.isNullOrWhiteSpace(triggerFieldPublicIds) &&
          triggerFieldPublicIds.indexOf(element.attr("id")) > -1
        ) {
          if (isSkipField) return;

          var theFieldId = $(this).data("publicid");

          if (String.isNullOrWhiteSpace(element.val())) {
            self.$root.childValuesEmpty(theFieldId);
            self.$root.childValuesEmpty(triggerFieldPublicIds);
            return true;
          }

          var req = {
            publicId: theFieldId,
            fieldsValues: [],
          };

          var fields = [];
          if (self.$route.meta.isDetailPage) {
            var forms = $(formId);
            forms.each(function (i, v) {
              var form = $(v),
                item = form.find(
                  String.format(
                    "input:not([type=hidden]):not(.hidden), textarea, select"
                  )
                );

              fields.push(item);
            });
          } else {
            var elements = $(formId).find(
              String.format(
                '{0}[data-istable="false"] input:not([type=hidden]):not(.hidden), {0}[data-istable="false"] textarea, {0}[data-istable="false"] select',
                self.$systemFieldPrefix
              )
            );
            elements.each(function (i, v) {
              fields.push(v);
            });
          }

          fields.forEach(function (v) {
            var field = $(v),
              fieldId = field.attr("id"),
              pageItem = $("#" + fieldId).closest(self.$systemFieldPrefix),
              formulaName = pageItem.data("formulaname"),
              isMultipleValue = pageItem.data("ismultiplevalue"),
              isRadio = pageItem.data("isradio"),
              fieldType = pageItem.data("fieldtype"),
              value = $("#" + fieldId).val();

            if (field.hasClass("vti__input")) {
              value = field.val();
              fieldType = "Phone";
              fieldId = field.attr("id");
            } else if (fieldType == "Checkbox") {
              value = $("#" + fieldId)
                .is(":checked")
                .toString();
            } else if (isRadio) {
              fieldId = pageItem.data("publicid");
              if (
                !String.isNullOrWhiteSpace(
                  req.fieldsValues.find((a) => a.key == formulaName)
                )
              ) {
                return;
              }

              value = $("[name=" + fieldId + "]:checked");
              if (value.length > 0) {
                var radioSelectedValues = [];
                $(value).each((i, element) => {
                  radioSelectedValues.push($(element).val());
                });
                value = radioSelectedValues.join(self.$systemSeparator);
              } else {
                value = "";
              }
            } else if (self.fieldTypes.select.includes(fieldType)) {
              value = $("#" + fieldId).val();

              if (!String.isNullOrWhiteSpace(value) && $.isArray(value))
                value = value.join(self.$systemSeparator);
            }

            if (
              String.isNullOrWhiteSpace(fieldId) ||
              String.isNullOrWhiteSpace(value) ||
              String.isNullOrWhiteSpace(fieldType) ||
              fieldType === "Html"
            )
              return;

            req.fieldsValues.push({
              key: formulaName,
              value: value,
            });
          });

          req.fieldsValues = req.fieldsValues.filter(function (f) {
            return !f.value.includes("error");
          });

          self.getFieldDefaultValue(self, theFieldId, {
            customObjectPublicId: formulaField.customObjectPublicId,
            data: JSON.stringify(req),
          });
        }
      });
    },
    calculateTriggerEditCellField(parent, selectedRow, selectedCell) {
      if (!parent.selectedRecordCellField.isActive || !selectedRow) return;

      var childs = selectedRow.find(
        `[data-celledit-calculatetriggerfieldpublicids*='${parent.selectedRecordCellField.publicId}']`
      );
      if (childs.length === 0) return;

      var gridItemPrefix = "grid-item",
        element = selectedCell,
        self = this;

      selectedRow
        .find(`[data-celledit-class='${gridItemPrefix}']:visible`)
        .each(function (i, v) {
          var item = $(v),
            triggerFieldPublicIds = item.data(
              "celledit-calculatetriggerfieldpublicids"
            ),
            iscalculateonclientchange = item.data(
              "celledit-iscalculateonclientchange"
            ),
            fieldId = item.data("celledit-publicid"),
            isCalculatedOnlyOnInsert = item.data(
              "celledit-iscalculatedonlyoninsert"
            ),
            isSkipField =
              !self.$route.meta.isNewPage && isCalculatedOnlyOnInsert;
          if (
            iscalculateonclientchange &&
            !String.isNullOrWhiteSpace(triggerFieldPublicIds) &&
            triggerFieldPublicIds.indexOf(element.data("celledit-publicid")) >
              -1
          ) {
            if (isSkipField) return;

            if (String.isNullOrWhiteSpace(element.text())) {
              //TODO !!
              // self.$root.childValuesEmpty(theFieldId);
              // self.$root.childValuesEmpty(triggerFieldPublicIds);
              return true;
            }

            var req = {
                publicId: fieldId,
                fieldsValues: [],
              },
              fields = [],
              elements = selectedRow.find("td[data-celledit-publicid]");

            elements.each(function (i, v) {
              fields.push(v);
            });

            fields.forEach(function (v) {
              var field = $(v),
                value = field.text(),
                fieldId = field.data("celledit-publicid"),
                formulaName = field.data("celledit-formulaname"),
                fieldType = field.data("celledit-fieldtype");

              if (
                String.isNullOrWhiteSpace(fieldId) ||
                String.isNullOrWhiteSpace(value) ||
                value == self.$valueNotAvailableSeparator ||
                String.isNullOrWhiteSpace(fieldType) ||
                fieldType === "Html"
              )
                return;

              value = parent.selectedRowData[formulaName];
              if (
                self.fieldTypes.select.includes(fieldType) ||
                self.fieldTypes.file.includes(fieldType)
              ) {
                value =
                  parent.selectedRowData[
                    formulaName + self.$fieldPublicIdFormulaExtension
                  ];
              }

              req.fieldsValues.push({
                key: formulaName,
                value: value,
              });
            });

            req.fieldsValues = req.fieldsValues.filter(function (f) {
              return !f.value.includes("error");
            });

            parent.$root.getEditCellDefaultValue(parent, selectedRow, fieldId, {
              customObjectPublicId:
                parent.selectedRecordCellField.customObjectPublicId,
              data: JSON.stringify(req),
            });
          }
        });
    },
    getEditCellDefaultValue(
      editCellModalSelf,
      selectedRow,
      calculatedFieldPublicId,
      parameters
    ) {
      var pageItem = selectedRow.find(
          `[data-celledit-publicid=${calculatedFieldPublicId}]:visible`
        ),
        self = this;

      pageItem.text("").attr("style", this.setXrmInputSpinnerGifSrc);
      pageItem.addClass("cell-updated-border");

      self.$appAxios
        .post("/rws-SetController-GetFieldDefaultValue", parameters)
        .then((r) => {
          var result = r.data;

          var formElement = pageItem;
          if (formElement.length == 0) return;
          formElement.css("background", "");
          setTimeout(() => {
            pageItem.removeClass("cell-updated-border");
          }, 2000);

          if (result.isOk) {
            var fieldDefaultValue = result.value;

            if (fieldDefaultValue.includes("error")) return;

            var fieldType = pageItem.data("fieldtype");
            switch (fieldType) {
              case "DateTime":
              case "FormulaDateTime":
                formElement.text(self.$root.dateTimeFormat(fieldDefaultValue));
                break;
              case "Date":
              case "FormulaDate":
                formElement.text(self.$root.dateFormat(fieldDefaultValue));
                break;
              case "Time":
                formElement.text(fieldDefaultValue);
                break;
              case "Lookup":
                var ids = "",
                  texts = "";
                if (!String.isNullOrWhiteSpace(fieldDefaultValue)) {
                  var separator = fieldDefaultValue.includes(
                    self.$selectSeparator
                  )
                    ? self.$selectSeparator
                    : self.$systemSeparator;
                  ids = fieldDefaultValue.split(separator)[0];
                  texts = fieldDefaultValue.split(separator)[1];
                  formElement.text(texts);
                }
                break;
              case "FormulaOrganizationalUnit":
                var formulaIds = "",
                  formulaTexts = "";
                if (!String.isNullOrWhiteSpace(fieldDefaultValue)) {
                  var formulaSeparator = fieldDefaultValue.includes(
                    self.$selectSeparator
                  )
                    ? self.$selectSeparator
                    : self.$systemSeparator;
                  formulaIds = fieldDefaultValue.split(formulaSeparator)[0];
                  formulaTexts = fieldDefaultValue.split(formulaSeparator)[1];
                  formElement.text(texts);
                }
                break;
              default:
                formElement.text(fieldDefaultValue).trigger("change");
                break;
            }

            //Editing Cell Field Item Value Update
            var record = editCellModalSelf.$parent.pagedItems.find(
                (f) => f.PUBLICID == editCellModalSelf.selectedRowData.PUBLICID
              ),
              recordKey = pageItem.data("celledit-formulaname");
            if (
              String.isNullOrWhiteSpace(record) ||
              String.isNullOrWhiteSpace(recordKey)
            )
              return;

            record[recordKey] = fieldDefaultValue;
            if (record[recordKey + this.$fieldPublicIdFormulaExtension]) {
              formulaSeparator = fieldDefaultValue.includes(
                self.$selectSeparator
              )
                ? self.$selectSeparator
                : self.$systemSeparator;

              record[recordKey] = fieldDefaultValue.split(separator)[1];
              record[recordKey + this.$fieldPublicIdFormulaExtension] =
                fieldDefaultValue.split(separator)[0];
            }

            self.newChangeEvent(formElement.data("celledit-publicid"));
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    preparePhoneNumber(fieldPublicId, value) {
      const phoneSelector = document.getElementById(fieldPublicId),
        settings = {
          iti: {
            dropdownContainer: document.body,
            nationalMode: false,
            placeholderNumberType: "MOBILE",
            showSelectedDialCode: true,
            preferredCountries: ["tr"],
            initialCountry: process.env.VUE_APP_INITIAL_COUNTRY_CODE,
            formatOnDisplay: false,
            formatAsYouType: false,
            utilsScript: "/extras/plugins/intl-tel-input/utils.js",
            hiddenInput: function (telInputName) {
              return {
                phone: `${fieldPublicId}_full_phone_number`,
                country: `${fieldPublicId}_country_code`,
              };
            },
            customPlaceholder: function (
              selectedCountryPlaceholder,
              selectedCountryData
            ) {
              if (String.isNullOrWhiteSpace(selectedCountryPlaceholder))
                return "";

              var im = new Inputmask(
                selectedCountryPlaceholder.replace(
                  settings.im.regex,
                  settings.im.replacement
                ),
                settings.im
              );
              im.mask(phoneSelector);

              return selectedCountryPlaceholder;
            },
            i18n: {
              searchPlaceholder: this.$t(
                "BaseModelFields.Search",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          im: {
            clearMaskOnLostFocus: true,
            regex: new RegExp("\\d", "gm"),
            replacement: "9",
          },
        },
        iti = intlTelInput(phoneSelector, settings.iti);

      var hiddenCountryCodeInput = $(`[name="${fieldPublicId}_country_code"]`),
        hiddenPhoneInput = $(`[name="${fieldPublicId}_full_phone_number"]`);
      if (!String.isNullOrWhiteSpace(value)) {
        var splitedValue = value.split(" "),
          dialCode = splitedValue[0];

        iti.setNumber(value);

        if (hiddenCountryCodeInput) {
          hiddenCountryCodeInput.val(dialCode);
        }

        if (hiddenPhoneInput) {
          hiddenPhoneInput.val(value);
        }
      }

      phoneSelector.addEventListener("keyup", function (e) {
        // eslint-disable-next-line no-undef
        var dialCode = iti.getSelectedCountryData().dialCode,
          value = `+${dialCode} ${e.target.value}`;

        if (!hiddenPhoneInput) return;

        if (String.isNullOrWhiteSpace(e.target.value)) hiddenPhoneInput.val("");
        else hiddenPhoneInput.val(value.replaceAll("_", "").trimEnd());

        if (iti.isValidNumber() || String.isNullOrWhiteSpace(e.target.value)) {
          phoneSelector.classList.remove("intl-input-invalid");
        } else {
          phoneSelector.classList.add("intl-input-invalid");
        }
      });

      phoneSelector.addEventListener("countrychange", function () {
        var selectedCountryData = iti.getSelectedCountryData();

        // eslint-disable-next-line no-undef
        var placeholder = intlTelInputUtils.getExampleNumber(
          selectedCountryData.iso2,
          settings.iti.nationalMode,
          // eslint-disable-next-line no-undef
          intlTelInputUtils.numberType[settings.iti.placeholderNumberType]
        );

        var dialCode = `+${selectedCountryData.dialCode}`;
        if (hiddenCountryCodeInput) {
          hiddenCountryCodeInput.val(dialCode);
        }

        if (hiddenPhoneInput) {
          hiddenPhoneInput.val("");
        }

        iti.setNumber("");

        phoneSelector.classList.remove("intl-input-invalid");

        if (String.isNullOrWhiteSpace(placeholder)) return;

        if (settings.iti.showSelectedDialCode) {
          placeholder = placeholder
            .split(" ")
            .map((e, i) => (i > 0 ? e : undefined))
            .filter((x) => x)
            .join(" ");
        }

        var im = new Inputmask(
          placeholder.replace(settings.im.regex, settings.im.replacement),
          settings.im
        );
        im.mask($(this));
      });
    },
    inputMaskPatternReplace(field) {
      if (!field) return null;

      if (!this.fieldTypes.number.includes(field.fieldType)) return null;

      var formatInfo = this.getNumberFormat(),
        word = String.replaceAll(
          field.inputMaskPattern,
          "+",
          formatInfo.decimal
        );

      word = String.replaceAll(word, "*", formatInfo.group);
      word = String.replaceAll(word, "#", "0");

      return word;
    },
    //Start Set Pages(New,Detail,Edit,Calendar..) Functions

    //Start Grid Global Functions
    gridSortOption(type, table) {
      var self = this,
        $parent = type === "LookupRelation" ? table.viewFilter : table;
      var tableSortOptions = {
          columns: [],
        },
        visibleFields = $parent.visibleFields,
        hiddenFields = $parent.hiddenFields;
      if (
        visibleFields.length > 0 &&
        !String.isNullOrWhiteSpace($parent.orderingFieldPublicId) &&
        !String.isNullOrWhiteSpace($parent.ordering)
      ) {
        $.each(
          $parent.orderingFieldPublicId.split(self.$systemSeparator),
          function (i, v) {
            var visibleOrderField = visibleFields.find(
              (f) => f.fieldModel.publicId == v.trim()
            );
            if (visibleOrderField) {
              tableSortOptions.columns.push({
                field: visibleOrderField.fieldModel.formulaName,
                direction: Boolean.toBool(
                  $parent.ordering.split(self.$systemSeparator)[i]
                )
                  ? "Ascending"
                  : "Descending",
              });
            }
          }
        );
      }
      return tableSortOptions;
    },
    gridFontSizeChanger(self, fontSize) {
      if (fontSize > self.maxFontSize) {
        fontSize = self.currentFontSize = self.minFontSize;
      }

      $(`#${self.gridId} .dx-datagrid`)
        .find(
          "td, i:not(.ignore-default-font-size), a, b, span, u, .badge, .dx-icon, .dx-page-sizes, .dx-pages"
        )
        .attr("style", `font-size:${fontSize}px !important;`);

      return fontSize;
    },
    gridDataChange(data, gridFields, isFormatingChange = true) {
      var values = [],
        items = [],
        self = this;

      if (!Array.isArray(data) || !data || data.length == 0) return [];

      if (isFormatingChange) {
        data.forEach((element, i) => {
          element.fieldValues["SERIALNO"] = {
            value: ++i,
            fieldType: "CustomProperty",
          };
          values.push(element.fieldValues);
        });
      } else {
        values = data;
      }

      values.forEach((element, i) => {
        if (items[i] == undefined) {
          items[i] = [];
        }

        const column = new Object();
        $.each(element, function (key, value) {
          var field = gridFields.find((f) => f.formulaName == key);
          if (key === "PUBLICID" || key == "SERIALNO") {
            column[key] = value.value;
          }

          if (String.isNullOrWhiteSpace(field)) return;

          column[key] = value.value;
          if (
            self.fieldTypes.select.includes(field.fieldType) ||
            self.fieldTypes.file.includes(field.fieldType)
          ) {
            column[
              String.format("{0}{1}", key, self.$fieldPublicIdFormulaExtension)
            ] = value.publicIdValue;
          }
        });
        items[i] = column;
      });

      return items;
    },
    childValuesEmptyForGrid(publicId) {
      var childs = $(`[data-calculatetriggerfieldpublicids*='${publicId}']`),
        self = this;
      if (childs.length === 0) return;

      childs.each(function (i, gridItem) {
        gridItem = $(gridItem);
        var formElementType = gridItem.data("inputtype"),
          isTelInput = gridItem.data("inputtype") === "tel",
          fieldType = gridItem.data("fieldtype"),
          editCellTemplateId = gridItem.data("editcelltemplateelementid"),
          domElement = document.getElementById(editCellTemplateId);
        if (formElementType == "text" || formElementType == "tel") {
          formElementType = "input";
        }

        if (self.validateDatePickerType(fieldType)) {
          var dxDateBoxInstance = self.getDxDateBoxInstance(
            document.getElementById(`${editCellTemplateId}-parent`)
          );
          if (dxDateBoxInstance) dxDateBoxInstance.reset();
          return;
        }

        var formElements = isTelInput
          ? gridItem.find(`${formElementType}:not([type="hidden"])`)
          : gridItem.find(formElementType);
        if (formElements.length == 0) return true;

        formElements.each(function (i, element) {
          var item = $(element);

          item.val("");
          if (isTelInput) {
            $(`[name="${editCellTemplateId}_full_phone_number"]`).val(null);
          }

          var options = item.find("option");
          if (options.length > 0) {
            options.remove();
          }

          if (formElementType != "input") {
            domElement.dispatchEvent(
              new UIEvent("select", {
                bubbles: true,
                cancelable: true,
              })
            );
          } else {
            domElement.dispatchEvent(
              new UIEvent(isTelInput ? "blur" : "change", {
                bubbles: true,
                cancelable: true,
              })
            );
          }
        });
      });
    },
    calculateTriggerFieldForGrid(self, template) {
      if (!template.field.isActive) return;

      var childs = $(
          `[data-calculatetriggerfieldpublicids*='${template.field.publicId}']`
        ),
        parent = template.child ? template.child : template.parent;
      if (childs.length === 0) return;

      var element = $(`[id=${template.id}]`),
        elementPropertyItem = element.closest('[data-class="grid-item"]'),
        appSelf = this;

      $(`#${self.gridId} [data-class='grid-item']`).each(function (i, v) {
        var gridItem = $(v),
          triggerFieldPublicIds = gridItem.data(
            "calculatetriggerfieldpublicids"
          ),
          iscalculateonclientchange = gridItem.data(
            "iscalculateonclientchange"
          ),
          isCalculatedOnlyOnInsert = gridItem.data("iscalculatedonlyoninsert"),
          isSkipField = parent.isDxRowInlineEditing && isCalculatedOnlyOnInsert;
        if (
          iscalculateonclientchange &&
          !String.isNullOrWhiteSpace(triggerFieldPublicIds) &&
          triggerFieldPublicIds.indexOf(elementPropertyItem.attr("data-id")) >
            -1
        ) {
          if (isSkipField) return;

          var fieldId = gridItem.data("publicid"),
            editCellTemplateId = gridItem.data("editcelltemplateelementid");

          if (String.isNullOrWhiteSpace(element.val())) {
            appSelf.childValuesEmptyForGrid(fieldId);
            appSelf.childValuesEmptyForGrid(triggerFieldPublicIds);
            return true;
          }

          var req = {
            publicId: fieldId,
            fieldsValues: [],
          };

          var fields = $(`#${self.gridId} [data-class='grid-item']`);
          fields.each(function () {
            var fieldGridItem = $(this),
              fieldId = fieldGridItem.data("id"),
              formulaName = fieldGridItem.data("formulaname"),
              fieldType = fieldGridItem.data("fieldtype"),
              field = fieldGridItem.find(
                `#${fieldGridItem.data("editcelltemplateelementid")}`
              ),
              value = field.val();

            if (
              String.isNullOrWhiteSpace(fieldId) ||
              String.isNullOrWhiteSpace(value) ||
              String.isNullOrWhiteSpace(fieldType) ||
              fieldType === "Html"
            )
              return;

            if ($.isArray(value)) {
              value = value.join(appSelf.$systemSeparator);
            }

            req.fieldsValues.push({
              key: formulaName,
              value: value,
            });
          });

          req.fieldsValues = req.fieldsValues.filter(function (f) {
            return !f.value.includes("error");
          });

          if (
            template.parent.isLookupRelationTable &&
            !req.fieldsValues.find(
              (f) => f.key == template.parent.table.fieldFormulaName
            ) &&
            !appSelf.$route.meta.isNewPage
          ) {
            req.fieldsValues.push({
              key: template.parent.table.fieldFormulaName,
              value: appSelf.$route.params.id,
            });
          }

          appSelf.getFieldDefaultValueForGrid(self, editCellTemplateId, {
            customObjectPublicId: template.field.customObjectPublicId,
            data: JSON.stringify(req),
          });
        }
      });
    },
    async getFieldItems(fieldPublicId) {
      return await this.$appAxios
        .get(`rws-FieldItems?id=${fieldPublicId}`)
        .then((response) => {
          return { fieldPublicId: fieldPublicId, items: response.data };
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    async getCalculateDefaultValueWithButton(requestModel) {
      return await this.$appAxios
        .post("rws-SetController-CalculateFormulas", requestModel)
        .then((response) => {
          return response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    async calculateFieldDefaultValue(model) {
      return await this.$appAxios
        .post("/rws-SetController-GetFieldDefaultValue", model)
        .catch(function (error) {
          //alert(error);
        });
    },
    getDxDateBoxInstance(el) {
      return dxDateBox.getInstance(el);
    },
    getFieldDefaultValueForGrid(
      tableSelf,
      calculatedFieldElementId,
      parameters
    ) {
      var grid = $(`#${tableSelf.gridId}`),
        gridInlineSaveButton = grid.find(".dx-grid-record-save:visible"),
        notRealSaveLoadingButton = grid.find(".dx-save-loading");

      gridInlineSaveButton.hide();
      if (notRealSaveLoadingButton.length == 0) {
        gridInlineSaveButton.after(
          '<button type="button" disabled class="btn btn-icon btn-success dx-save-loading"><div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div></button>'
        );
      }

      var gridItem = $(
          `[data-editcelltemplateelementid="${calculatedFieldElementId}"]`
        ),
        element = $(`[id="${calculatedFieldElementId}"]`);
      element.attr(
        "style",
        `${element.attr("style")} ${this.setXrmInputSpinnerGifSrc}`
      );

      var self = this;
      self.$appAxios
        .post("/rws-SetController-GetFieldDefaultValue", parameters)
        .then((response) => {
          grid.find(".dx-grid-record-save").show();
          grid.find(".dx-save-loading").remove();

          var result = response.data,
            fieldDefaultValue = result.value,
            isOk = result.isOk,
            formElement = $(`#${calculatedFieldElementId}`),
            domElement = document.getElementById(formElement.attr("id"));

          if (formElement.length == 0) return;
          formElement.css("background", "");

          var dxDateBoxInstance = null,
            fieldType = gridItem.data("fieldtype");
          if (this.validateDatePickerType(fieldType)) {
            dxDateBoxInstance = self.getDxDateBoxInstance(
              document.getElementById(`${calculatedFieldElementId}-parent`)
            );
          }

          switch (fieldType) {
            case "DateTime":
            case "FormulaDateTime":
              var dateTimeValue = this.$moment(
                fieldDefaultValue,
                this.getDateTimeFormat().calendar
              ).format("yyyy-MM-DDTHH:mm:ss");

              if (isOk) {
                dxDateBoxInstance.option("value", dateTimeValue);
              } else {
                dxDateBoxInstance.reset();
              }

              break;
            case "Date":
            case "FormulaDate":
              var dateValue = this.$moment(
                fieldDefaultValue,
                this.$root.getDateTimeFormat("short").calendar
              ).format("yyyy-MM-DD");

              if (isOk) {
                dxDateBoxInstance.option("value", dateValue);
              } else {
                dxDateBoxInstance.reset();
              }

              break;
            case "Time":
              var timeValue = this.$moment(
                fieldDefaultValue,
                this.$root.getDateTimeFormat("short").time
              ).format("yyyy-MM-DDTHH:mm");

              if (isOk) {
                dxDateBoxInstance.option("value", timeValue);
              } else {
                dxDateBoxInstance.reset();
              }
              break;
            case "Phone":
              var intlInstance = self.getPhoneInputInstance(
                  calculatedFieldElementId
                ),
                hiddenFullPhoneNumberInput = $(
                  `[name="${calculatedFieldElementId}_full_phone_number"]`
                );

              if (isOk && intlInstance) {
                intlInstance.setNumber(fieldDefaultValue);
                hiddenFullPhoneNumberInput.val(fieldDefaultValue);
              } else {
                formElement.val(null);
                fieldDefaultValue.val(null);
              }

              domElement.dispatchEvent(
                new UIEvent("blur", {
                  bubbles: true,
                  cancelable: true,
                })
              );
              break;
            case "Lookup":
            case "SelectList":
              if (isOk) {
                var ids = "",
                  texts = "";
                if (!String.isNullOrWhiteSpace(fieldDefaultValue)) {
                  var separator = fieldDefaultValue.includes(
                    self.$selectSeparator
                  )
                    ? self.$selectSeparator
                    : self.$systemSeparator;
                  ids = fieldDefaultValue.split(separator)[0];
                  texts = fieldDefaultValue.split(separator)[1];
                  self.select2SetValue(
                    formElement,
                    gridItem.data("ismultiplevalue"),
                    ids,
                    texts
                  );
                }
              } else {
                self.select2SetValue(
                  formElement,
                  gridItem.data("ismultiplevalue")
                );
              }

              domElement.dispatchEvent(
                new UIEvent("select", {
                  bubbles: true,
                  cancelable: true,
                })
              );
              break;
            case "FormulaOrganizationalUnit":
              if (isOk) {
                var formulaIds = "",
                  formulaTexts = "";
                if (!String.isNullOrWhiteSpace(fieldDefaultValue)) {
                  var formulaSeparator = fieldDefaultValue.includes(
                    self.$selectSeparator
                  )
                    ? self.$selectSeparator
                    : self.$systemSeparator;
                  formulaIds = fieldDefaultValue.split(formulaSeparator)[0];
                  formulaTexts = fieldDefaultValue.split(formulaSeparator)[1];
                  self.select2SetValue(
                    formElement,
                    gridItem.data("ismultiplevalue"),
                    formulaIds,
                    formulaTexts
                  );
                }
              } else {
                self.select2SetValue(
                  formElement,
                  gridItem.data("ismultiplevalue")
                );
              }

              break;
            default:
              if (isOk) {
                formElement.val(fieldDefaultValue);
              } else {
                formElement.val(null);
              }

              domElement.dispatchEvent(
                new UIEvent("change", {
                  bubbles: true,
                  cancelable: true,
                })
              );
              break;
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    select2PagedSelectlistDependencyChildTriggerForGrid(row, field, template) {
      // debugger;
      var self = this,
        parentGridItem = row.find(
          String.format(
            "[data-publicid='{0}'][data-class='grid-item']",
            field.controllingPublicId
          )
        ),
        parentField = parentGridItem.find(
          "#" + parentGridItem.data("editcelltemplateelementid")
        ),
        parentFieldValue = Array.isArray(parentField.val())
          ? parentField.val().join(self.$systemSeparator)
          : parentField.val(),
        childGridItems = $(
          String.format(
            "[data-controllingpublicid={0}]",
            field.controllingPublicId
          )
        );

      // ||
      // String.isNullOrWhiteSpace(parentFieldValue)
      if (childGridItems.length == 0) return;

      $.each(childGridItems, function (i, el) {
        var element = $(el),
          controllingFieldPublicId = element.data("publicid"),
          childField = element.find(
            "#" + element.data("editcelltemplateelementid")
          );

        //Relation child elements
        // var childRelationFieldPublicIds = self.getChildFieldPublicIds(
        //   controllingFieldPublicId
        // );
        // self.childFieldSelectlistSetNullValueForGrid(
        //   childRelationFieldPublicIds
        // );,

        if (String.isNullOrWhiteSpace(parentField.val())) {
          childField.select2({
            language: self.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            disabled: true,
            dropdownAutoWidth: false,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
          });
        } else {
          var ajaxUrl = null;
          switch (field.fieldType) {
            case "Predefined":
              ajaxUrl = self.getFieldRequestUrl("dependentPredefined", {
                id: parentFieldValue,
                predefinedDependencyPublicId: element.data(
                  "predefineddependencypublicid"
                ),
                controllingRecordId: parentGridItem.data("predefinedpublicid"),
              });
              break;
            case "OrganizationalUnit":
              ajaxUrl = self.getFieldRequestUrl("organizationalunit", {
                publicId: element.data("publicid"),
                name: element.data("organizationalunitname"),
                filterType: element.data("organizationalunitfiltertype"),
                groupIds: parentFieldValue,
                includeItself: element.data("organizationalunitincludeitself"),
                depth: element.data("organizationalunitdepth"),
                isAddCurrentKeys: false,
              });
              break;
            case "SelectList":
              ajaxUrl = self.getFieldRequestUrl("dependentSelect", {
                controllingRecordId: parentFieldValue,
                parentId: field.publicId,
              });
              break;
            case "Lookup":
              ajaxUrl = self.getFieldRequestUrl("lookup", {
                coId: field.customObjectPublicId,
                fieldPublicId: element.data("publicid"),
                controllingRecordId: parentFieldValue,
              });
              break;
            default:
              break;
          }

          if (String.isNullOrWhiteSpace(ajaxUrl)) {
            // console.error(
            //   "select2PagedSelectlistDependencyChildTriggerForGrid Ajax Url missing"
            // );
            return;
          }

          var childFieldSelect2Setting = {
            isEditable: element.data("iseditable"),
            isDisabled: element.data("isdisabled"),
            isMultiple: element.data("ismultiplevalue"),
          };
          if (template.parent.isDxRowInlineEditing) {
            childFieldSelect2Setting.isDisabled =
              !childFieldSelect2Setting.isEditable;
          }
          childField.select2({
            dropdownParent: null,
            language: self.getSelect2Locale(self.$store.state.activeLang),
            theme: "bootstrap-5",
            allowClear: !childFieldSelect2Setting.isDisabled,
            minimumInputLength: 0,
            maximumSelectionSize: 99,
            dropdownAutoWidth: false,
            closeOnSelect: true,
            disabled: childFieldSelect2Setting.isDisabled,
            multiple: childFieldSelect2Setting.isMultiple,
            separator: self.$systemSeparator,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
            ajax: self.getSelect2AjaxConfiguration(field, ajaxUrl),
            templateResult: function (d) {
              return Object.isHTML(d.text) ? $(d.text) : d.text;
            },
          });
        }
      });
    },
    childFieldSelectlistSetNullValueForGrid(childRelationFieldPublicIds) {
      if (childRelationFieldPublicIds.length > 0) {
        childRelationFieldPublicIds.forEach((element) => {
          var gridItem = $(
              String.format(
                "[data-class='grid-item'][data-publicid='{0}']",
                element
              )
            ),
            $el = gridItem.find(
              "#" + gridItem.data("editcelltemplateelementid")
            );

          if ($el.length == 0) {
            return;
          }

          var fieldType = gridItem.data("fieldtype"),
            isMultiple = gridItem.data("ismultiplevalue"),
            isCalcSystem =
              fieldType.includes("Formula") || fieldType == "AutoId"
                ? true
                : false;

          //TODO: Dependency field operation
          // if(isMultiple){

          // }
          // debugger;

          var lookupDetailButton = gridItem.find(".lookup-detail-button");
          if (lookupDetailButton) {
            lookupDetailButton.addClass("hidden");
          }

          $el.val(null);
          $el.select2({
            language: this.getSelect2Locale(this.$store.state.activeLang),
            theme: "bootstrap-5",
            disabled: true,
            multiple: isMultiple,
            placeholder: isCalcSystem
              ? this.$t(
                  "BaseModelFields.FieldIsCalcForSystem",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              : this.$t(
                  "BaseModelFields.Choose",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
            ajax: this.$root.getSelect2AjaxConfiguration(
              { fieldType: "NotSelectlist" },
              ""
            ),
          });
        });
      }
    },
    selectPagedSelectlistSelectionForGrid(
      field,
      id,
      gridAllFields,
      gridId,
      template
    ) {
      var grid = $("#" + gridId),
        parentField = grid.find(String.format("[data-id={0}]", field.publicId)),
        childGridItems = grid.find(
          String.format("[data-controllingpublicid={0}]", field.publicId)
        ),
        select2LocaleObj = this.getSelect2Locale(this.$store.state.activeLang),
        self = this;

      if (childGridItems.length == 0) return;

      //Relation child elements
      var childRelationFieldPublicIds = self.$root.getChildFieldPublicIds(
        field.publicId
      );
      self.$root.childFieldSelectlistSetNullValueForGrid(
        childRelationFieldPublicIds
      );

      $.each(childGridItems, function (i, el) {
        var element = $(el),
          currentField = gridAllFields.find(
            (f) => f.formulaName === element.data("formulaname")
          ),
          currentFieldSelectList = currentField.fieldType == "SelectList",
          controllingFieldPublicId = element.data("publicid"),
          childField = $(
            String.format("[data-id={0}]", controllingFieldPublicId)
          );

        if (String.isNullOrWhiteSpace(currentField)) return;

        //Relation child elements
        var childRelationFieldPublicIds = self.getChildFieldPublicIds(
          controllingFieldPublicId
        );
        self.childFieldSelectlistSetNullValueForGrid(
          childRelationFieldPublicIds
        );

        var select = $(`#${childField.data("editcelltemplateelementid")}`);
        select.val(null);

        if (String.isNullOrWhiteSpace(id)) {
          select.val(null);
          select.select2({
            language: select2LocaleObj,
            theme: "bootstrap-5",
            allowClear: false,
            maximumSelectionSize: 99,
            dropdownAutoWidth: false,
            closeOnSelect: true,
            disabled: true,
            multiple: currentField.isMultipleValue,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
          });
        } else {
          var ajaxUrl = null;
          switch (field.fieldType) {
            case "Predefined":
              ajaxUrl = self.getFieldRequestUrl("dependentPredefined", {
                id: id,
                predefinedDependencyPublicId: element.data(
                  "predefineddependencypublicid"
                ),
                controllingRecordId: field.predefinedPublicId,
              });
              break;
            case "OrganizationalUnit":
              ajaxUrl = self.getFieldRequestUrl("organizationalunit", {
                publicId: element.data("publicid"),
                name: element.data("organizationalunitname"),
                filterType: element.data("organizationalunitfiltertype"),
                groupIds: id,
                includeItself: element.data("organizationalunitincludeitself"),
                depth: element.data("organizationalunitdepth"),
                isAddCurrentKeys: false,
              });
              break;
            case "SelectList":
              ajaxUrl = self.getFieldRequestUrl("dependentSelect", {
                controllingRecordId: id,
                parentId: field.publicId,
              });
              break;
            case "Lookup":
              ajaxUrl = self.getFieldRequestUrl("lookup", {
                coId: field.customObjectPublicId,
                fieldPublicId: element.data("publicid"),
                controllingRecordId: id,
              });
              break;
            default:
              break;
          }

          if (String.isNullOrWhiteSpace(ajaxUrl)) {
            // console.error(
            //   "selectPagedSelectlistSelectionForGrid Ajax Url missing"
            // );
            return;
          }

          var childFieldSelect2Setting = {
            isEditable: element.data("iseditable"),
            isDisabled: element.data("isdisabled"),
            isMultiple: element.data("ismultiplevalue"),
          };
          if (template.parent.isDxRowInlineEditing) {
            childFieldSelect2Setting.isDisabled =
              !childFieldSelect2Setting.isEditable;
          }
          select.select2({
            language: select2LocaleObj,
            theme: "bootstrap-5",
            allowClear: !childFieldSelect2Setting.isDisabled,
            maximumSelectionSize: 99,
            minimumInputLength: 0,
            dropdownAutoWidth: false,
            closeOnSelect: true,
            disabled: childFieldSelect2Setting.isDisabled,
            multiple: childFieldSelect2Setting.isMultiple,
            separator: self.$systemSeparator,
            placeholder: self.$t(
              "BaseModelFields.Choose",
              {},
              { locale: self.$store.state.activeLang }
            ),
            ajax: self.getSelect2AjaxConfiguration(currentField, ajaxUrl),
            templateResult: function (d) {
              return Object.isHTML(d.text) ? $(d.text) : d.text;
            },
          });
        }
      });
    },
    validateDatePickerType(type) {
      return (
        this.fieldTypes.date.includes(type) ||
        this.fieldTypes.datetime.includes(type) ||
        this.fieldTypes.time.includes(type)
      );
    },
    newChangeEventForGrid(fieldPublicId) {
      var childs = $(
        String.format(
          "[data-calculatetriggerfieldpublicids*='{0}']",
          fieldPublicId
        )
      );
      if (childs.length === 0) return;

      this.formItemNewChangeEvent([fieldPublicId]);
    },
    rollupTrigger(tableId, allFields) {
      var self = this,
        activeLanguage = self.$store.state.activeLang;
      $("div[data-istablerollup='true']").each(function () {
        var $this = $(this),
          table = $(String.format("#Table_{0}", tableId)),
          fieldId = $this.data("rollupfield"),
          formula = $this.data("rollupformula"),
          decimalPlaces = $this.data("decimalplaces"),
          result = 0,
          max = 0,
          min = 999999999999,
          average = 0;

        var tableRows = table.find("tr"),
          rollupFields = tableRows.find(
            String.format('td[data-publicid="{0}"]', fieldId)
          );

        if (rollupFields.length == 0) {
          rollupFields = tableRows.find(
            String.format('td[data-celledit-publicid="{0}"]', fieldId)
          );
        }

        rollupFields.each(function () {
          var value = this.innerHTML;
          if (
            String.isNullOrWhiteSpace(value) ||
            (!String.isNullOrWhiteSpace(value) &&
              value == self.$valueNotAvailableSeparator)
          ) {
            value = "0";
          }

          var stringValue = Number.calcSeparatorRemove(value, activeLanguage);
          switch (formula) {
            case "Sum":
              result += parseFloat(stringValue);
              break;
            case "Count":
              result += 1;
              break;
            case "Max":
              if (max < parseFloat(stringValue)) {
                max = parseFloat(stringValue);
                result = max;
              }
              break;
            case "Min":
              if (min > parseFloat(stringValue)) {
                min = parseFloat(stringValue);
                result = min;
              }
              break;
            case "Avg":
              average += parseFloat(stringValue);
              result = average / table.find("tbody tr.sx-record-item").length;
              break;
            default:
              return;
          }
        });

        var element = $this.find("input").first();

        element.val(Number.priceFormat(result, decimalPlaces, activeLanguage));

        //default value trigger
        self.$root.calculateTriggerField(
          allFields.find((f) => f.publicId == $this.data("publicid"))
        );
      });
    },
    rollupTriggerForPagingTable(tableId, allFields) {
      setTimeout(() => {
        var self = this,
          fields = allFields.filter(
            (f) => f.isActive && !f.isCalculatedOnlyOnInsert
          );

        if (fields.length == 0) return;

        this.getRecordByFields(
          this.$route.params.key,
          this.$route.params.id,
          fields.map((m) => m.formulaName)
        ).then((fieldValues) => {
          if (fieldValues) {
            var childFormulaFields = [],
              cards = $(".card");

            fields
              .filter((f) => f.fieldType.includes("Rollup"))
              .forEach((rollupField) => {
                var valueObj = fieldValues[rollupField.formulaName],
                  pageItem = $(
                    ".page-item[data-publicid=" + rollupField.publicId + "]"
                  ),
                  viewElement = pageItem.find(`span#${rollupField.publicId}`);

                if (String.isNullOrWhiteSpace(valueObj) || !pageItem) return;

                if (this.$route.meta.isDetailPage) {
                  viewElement.text(valueObj.value);

                  cards.addClass("z-index-0");
                  pageItem.addClass("cell-updated-in-detail-page");
                  setTimeout(() => {
                    cards.removeClass("z-index-0");
                    pageItem.removeClass("cell-updated-in-detail-page");
                  }, 2000);
                }

                var input = pageItem.find(`input#${rollupField.publicId}`);
                if (input) {
                  input.val(valueObj.value);
                }

                var childs =
                  self.getChildFieldPublicIdsForCalculateTriggerField(
                    rollupField.publicId
                  );
                childs.forEach((childPublicId) => {
                  childFormulaFields.push(childPublicId);
                });
              });

            if (childFormulaFields.length > 0) {
              childFormulaFields
                .filter((x, i, a) => a.indexOf(x) == i)
                .forEach((fieldPublicId) => {
                  var childField = allFields.find(
                    (f) => f.publicId == fieldPublicId
                  );
                  if (!childField) return;

                  var childValueObj = fieldValues[childField.formulaName],
                    childPageItem = $(
                      ".page-item[data-publicid=" + fieldPublicId + "]"
                    ),
                    childViewElement = childPageItem.find(
                      `span#${fieldPublicId}`
                    );

                  if (
                    String.isNullOrWhiteSpace(childValueObj) ||
                    !childPageItem
                  )
                    return;

                  if (this.$route.meta.isDetailPage) {
                    childViewElement.text(childValueObj.value);

                    cards.addClass("z-index-0");
                    childPageItem.addClass("cell-updated-in-detail-page");
                    setTimeout(() => {
                      cards.removeClass("z-index-0");
                      childPageItem.removeClass("cell-updated-in-detail-page");
                    }, 2000);
                  }

                  var childInput = childPageItem.find(`input#${fieldPublicId}`);
                  if (childInput) {
                    childInput.val(childValueObj.value);
                  }
                });
            }
          }
        });
      }, 1000);
    },
    //End Grid Global Functions
  },
  mounted() {
    var currentLocation = Object.readCookie("CurrentLocation");
    if (String.isNullOrWhiteSpace(currentLocation)) {
      this.getLocation();
    }

    this.checkSession();
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
  created() {
    this.applyTheme();

    //set document fav icon
    var documentHead = document.getElementsByTagName("head")[0],
      newLink = document.createElement("link");
    newLink.rel = "shortcut icon";
    newLink.href = this.$config.media.logos.icon;
    documentHead.appendChild(newLink);

    //set company dependencies like selector
    Object.values(this.bodyCustomKeys).forEach((value, idx, array) => {
      if (this.bodyCustomKeys.notLikeValues.find((f) => f == value)) {
        return;
      }
      this.bodyCustomKeys.likeSelector += `[id*='${value}']${
        idx === array.length - 1 ? "" : ","
      }`;
    });

    loadMessages(trMessages);
    loadMessages(deMessages);
    locale(
      localStorage.getItem("activeLang") ||
        process.env.VUE_APP_I18N_LOCALE ||
        "tr"
    );
  },
  watch: {
    $route() {
      this.$SXWindowFunctions.removeEventListeners();

      removeModalBackdrops();
      removeModals();
      removeTooltips();

      var pageDependencies = $(
        `[id*='${this.bodyCustomKeys.script}'], [id*='${this.bodyCustomKeys.customPage}']`
      );
      if (pageDependencies) pageDependencies.remove();
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~sweetalert2/dist/sweetalert2.css";

// Main style scss
// @import "assets/sass/plugins";
@import "assets/sass/style";
</style>
