<script>
import { createToast } from "mosha-vue-toastify";
import * as signalR from "@microsoft/signalr";
export default {
  name: "SignalR",
  data() {
    return {
      connection: null,
      browserTabIsActive: true,
      channel: {
        url: `${
          process.env.NODE_ENV === "development"
            ? process.env.VUE_APP_LOCAL_API_URL
            : this.$config.api.gateway
        }rws-notificationhub`,
        prefix: this.$store.getters._companyId,
        separator: process.env.VUE_APP_SIGNALR_CHANNEL_SEPARATOR,
        logLevel: signalR.LogLevel.Trace,
        hubs: {
          notifications: "NotificationChannel",
          notificationCount: "NotificationCountChannel",
          loginDetect: "LoginDetectChannel",
        },
        options: {
          skipNegotiation: false,
          transport: signalR.HttpTransportType.LongPolling,
          headers: {
            "Content-Type": "text/plain;charset=UTF-8",
            Accept: "*/*",
          },
          withCredentials: true,
          timeout: 120000,
        },
      },
    };
  },
  watch: {
    browserTabIsActive() {
      if (
        this.connection.state !== signalR.HubConnectionState.Connected &&
        this.browserTabIsActive
      ) {
        this.startConnection();
      }
    },
  },
  methods: {
    createConnection() {
      var self = this;
      this.connection = new signalR.HubConnectionBuilder()
        .configureLogging(this.channel.logLevel)
        .withUrl(this.channel.url, {
          ...this.channel.options,
          accessTokenFactory: async () => {
            return localStorage.getItem("token");
          },
          httpClient: {
            async send(options) {
              let response = null;
              if (options.method === "POST") {
                response = await self.$appAxios.post(
                  options.url,
                  options.content,
                  {
                    headers: { ...options.headers },
                  }
                );
              } else if (options.method === "DELETE") {
                response = await self.$appAxios.delete(options.url, {
                  headers: { ...options.headers },
                });
              } else {
                response = await self.$appAxios.get(options.url, {
                  headers: { ...options.headers },
                });
              }

              // console.log(options.url, response);

              var data = response.data,
                isString = typeof data === "string" || data instanceof String;

              return {
                statusCode: response.status,
                statusText: response.statusText,
                content: isString ? data : JSON.stringify(data),
              };
            },
          },
        })
        // .withAutomaticReconnect()
        .build();

      this.startConnection().then(() => {
        this.connection.onclose((e) => {
          if (this.browserTabIsActive) {
            this.startConnection();
          }
        });

        // this.connection.onreconnecting((error) => {
        //   console.log("reconnecting");
        // });

        // this.connection.onreconnected((connectionId) => {
        //   console.log(`reconnected ${connectionId}`);
        // });

        this.connection.on(
          `${this.channel.prefix}${this.channel.separator}${this.channel.hubs.notifications}`,
          (notification) => {
            this.connection.invoke("GetNotificationCount");

            if (!this.browserTabIsActive) return;

            createToast(notification, {
              showIcon: true,
              hideProgressBar: true,
              timeout: -1,
              position: "top-right",
              type: "info",
              transition: "zoom",
            });
          }
        );
        this.connection.on(
          `${this.channel.prefix}${this.channel.separator}${this.channel.hubs.loginDetect}`,
          (notification) => {
            if (!this.browserTabIsActive) return;
            console.log(notification);
          }
        );
        if (this.$store.getters._isSystemNotificationActive) {
          this.connection.on(
            `${this.channel.prefix}${this.channel.separator}${this.channel.hubs.notificationCount}`,
            (notificationCount) => {
              this.$store.commit(
                "setChannelNotificationCount",
                notificationCount
              );
            }
          );
        }
      });
    },
    async startConnection() {
      if (
        this.connection &&
        this.connection.state !== signalR.HubConnectionState.Connecting
      ) {
        await this.connection
          .start()
          .then(() => {
            // console.log("Connected!");
            // this.connection.invoke("GetNotificationCount");
          })
          .catch((err) => console.error(err));
      }
    },
    async stopConnection() {
      if (
        this.connection &&
        this.connection.state === signalR.HubConnectionState.Connected
      ) {
        await this.connection
          .stop()
          .then(() => {
            // console.log("Stopped!");
          })
          .catch((err) => console.error(err));
      }
    },
  },
  unmounted() {
    this.stopConnection();
  },
  created() {
    this.createConnection();
  },
};
</script>
