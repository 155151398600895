import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { config } from "vuex-module-decorators";
const ls = new SecureLS({ isCompression: false });

import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

const getDefaultState = () => {
  return {
    isMultiLanguage: false,
    localizationValues: [],

    channelNotificationCount: 0,

    userPublicId: null,
    userData: null,
    companyData: null,
    token: null,
    refreshToken: null,
    tokenExpiration: null,

    activeTheme:
      localStorage.getItem("activeTheme") ||
      process.env.VUE_APP_DEFAULT_BOOTSWATCH_THEME ||
      null,
    activeLang:
      localStorage.getItem("activeLang") ||
      process.env.VUE_APP_I18N_LOCALE ||
      null,

    setPageLayoutAvailable: false,
    historyPanelShow: false,
    setPageDto: null,
    customPageDto: null,
    setPagesCRUDResult: null,
    gridEditSettings: null,
    validationRuleWarnings: null,
    internalErrorDescription: null,
  };
};

config.rawError = true;
const store = createStore({
  state: getDefaultState(),
  mutations: {
    initUserData(state, userData) {
      state.userData = userData;
    },
    logOutUser(state) {
      const currentTheme =
          localStorage.getItem("activeTheme") ||
          process.env.VUE_APP_DEFAULT_BOOTSWATCH_THEME ||
          null,
        activeLanguage =
          localStorage.getItem("activeLang") ||
          process.env.VUE_APP_I18N_LOCALE ||
          null;

      Object.assign(state, getDefaultState());
      localStorage.clear();

      localStorage.setItem("activeTheme", currentTheme);
      localStorage.setItem("activeLang", activeLanguage);
    },
    setUser(state, userData) {
      state.userData = userData;
      state.token = userData.token;
      state.refreshToken = userData.refreshToken;
      state.isMultiLanguage = userData.isMultiLanguage;
      state.tokenExpiration = userData.expiration;
      state.activeTheme = userData.activeTheme;
      state.userPublicId = userData.publicId;
      localStorage.setItem("userPublicId", state.userPublicId);
      localStorage.setItem("token", encodeURIComponent(state.token));
      localStorage.setItem(
        "refreshToken",
        encodeURIComponent(state.refreshToken)
      );
      localStorage.setItem("tokenExpirationDate", state.tokenExpiration);
      localStorage.setItem("activeTheme", state.activeTheme);
    },
    setCompany(state, companyData) {
      state.companyData = companyData;
    },
    setTheme(state, theme) {
      state.activeTheme = theme;
      localStorage.setItem("activeTheme", theme);
    },
    setLocalizationValues(state, items) {
      state.localizationValues = items;
    },
    setToken(state, info) {
      localStorage.setItem("token", encodeURIComponent(info.accessToken));
      localStorage.setItem(
        "refreshToken",
        encodeURIComponent(info.refreshToken)
      );
      localStorage.setItem("tokenExpirationDate", info.accessTokenExpiration);
      state.token = info.accessToken;
      state.refreshToken = info.refreshToken;
      state.tokenExpiration = info.accessTokenExpiration;
    },
    setUserInfo(state, userInfo) {
      state.userData.informations = userInfo;
    },
    setActiveLang(state, lang) {
      state.activeLang = lang;
      localStorage.setItem("activeLang", lang);
    },
    setPageLayoutAvailable(state, value) {
      state.setPageLayoutAvailable = value;
    },
    setHistoryPanelVisibility(state, value) {
      state.historyPanelShow = value;
    },
    setPagesCRUDResult(state, value) {
      state.setPagesCRUDResult = value;
    },
    setPageDto(state, dto) {
      state.setPageDto = dto;
    },
    customPageDto(state, dto) {
      state.customPageDto = dto;
    },
    setGridEditSettings(state, value) {
      state.gridEditSettings = value;
    },
    setValidationRuleWarnings(state, value) {
      state.validationRuleWarnings = value;
    },
    setInternalErrorDescription(state, value) {
      state.internalErrorDescription = value;
    },
    setChannelNotificationCount(state, value) {
      state.channelNotificationCount = value;
    },
  },
  getters: {
    getSetPagesCRUDResult(state) {
      return state?.setPagesCRUDResult;
    },
    getUserData(state) {
      return state?.userData;
    },
    getCompanyData(state) {
      return state?.companyData;
    },
    userInfo(state) {
      return state?.userData?.informations;
    },
    userName(state) {
      return `${
        state?.userData?.firstName
      } ${state?.userData?.lastName.substring(0, 1)}.`;
    },
    firstName(state) {
      return state?.userData?.firstName;
    },
    surName(state) {
      return state?.userData?.lastName;
    },
    fullUserName(state) {
      return `${state?.userData?.firstName} ${state?.userData?.lastName}`;
    },
    email(state) {
      return state?.userData?.email;
    },
    getLocalizationValuesByParameters:
      (state) =>
      (
        parameters = {
          parentId: null,
          itemFormulaName: null,
          itemPublicId: null,
          itemTypeId: 1,
          firstOrDefault: true,
        }
      ) => {
        if (typeof parameters.firstOrDefault === "undefined")
          parameters.firstOrDefault = true;

        let items = state?.localizationValues;
        if (parameters.parentId) {
          items = items.filter(
            (f) => f.keyItemParentPublicId === parameters.parentId
          );
        }

        if (parameters.itemTypeId) {
          items = items.filter(
            (f) => f.keyItemTypeId === parameters.itemTypeId
          );
        }

        if (parameters.itemFormulaName) {
          items = items.filter(
            (f) => f.keyItemFormulaName === parameters.itemFormulaName
          );
        } else if (parameters.itemPublicId) {
          items = items.filter(
            (f) => f.keyItemPublicId === parameters.itemPublicId
          );
        }

        if (items.length == 0) return null;

        return parameters.firstOrDefault ? items[0] : items;
      },
    getPermissionsOfCustomObjects:
      (state) =>
      (
        parameters = {
          customObjectPublicId: null,
        }
      ) => {
        const permissionsOfCustomObjects =
          state?.userData?.informations?.permissionsOfCustomObjects;
        if (!permissionsOfCustomObjects) return {};

        if (parameters.customObjectPublicId) {
          return permissionsOfCustomObjects.find(
            (f) => f.customObjectPublicId === parameters.customObjectPublicId
          );
        }
      },
    _isAuthenticated: (state) => state?.userData !== null,
    _currentUserId: (state) => state?.userData?.publicId,
    _activeLang: (state) => state?.activeLang,
    _token: (state) => state?.token,
    _isMultiLanguage: (state) => state?.isMultiLanguage,
    _isSideBarActive: (state) =>
      state?.userData?.informations?.activeMenu?.isSideBarActive,
    _isChatActive: (state) =>
      state?.userData?.informations?.activeMenu?.isChatActive,
    _isDeletegationActive: (state) =>
      state?.userData?.informations?.activeMenu?.isDelegationActive,
    _isDocumentActive: (state) =>
      state?.userData?.informations?.activeMenu?.isDocumentActive,
    _isSystemNotificationActive: (state) =>
      state?.userData?.informations?.activeMenu?.isSystemNotificationActive,
    _permissionGroups: (state) =>
      state?.userData?.informations?.permissionGroups,
    _userGroups: (state) => state?.userData?.informations?.userGroups,
    _companyName: (state) => state?.userData?.informations?.company?.name,
    _companyId: (state) => state?.userData?.informations?.company?.id,
    _setPageLayoutAvailable: (state) => state?.setPageLayoutAvailable,
    _getSetPageFilterDto: (state) => state?.setPageDto?.filter,
    _historyPanelVisibility: (state) => state?.historyPanelShow,
    _isSuperAdmin: (state) => state?.userData?.informations?.isSuperAdmin,
  },
  modules: {
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default store;
