<template>
  <div class="sx-radio-select p-0">
    <div
      class="field-external-buttons"
      v-if="
        items.length > 0 && !String.isNullOrWhiteSpace(radioSelectlistValue)
      "
    >
      <template v-if="$isMobile()">
        <button
          type="button"
          class="btn btn-danger p-1 btn-xs mb-1 w-100"
          @click="resetValue"
        >
          {{
            $t(
              "BaseModelFields.Clear",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </template>
      <template v-else>
        <i
          tabindex="1"
          @focus="focusedExternalButtons"
          @click="resetValue"
          class="cursor-pointer fa fa-times text-danger"
        >
        </i>
      </template>
    </div>
    <div
      class="btn-group btn-group-sm"
      :class="{
        'd-block': items.length > 2,
      }"
      role="group"
    >
      <template v-if="items.length > 0">
        <template v-for="(item, i) in items" :key="i">
          <input
            :type="prefix"
            class="btn-check"
            :name="template.id"
            :id="`${template.id}_${i}`"
            :value="item.key"
            :checked="
              template.field.isMultipleValue &&
              !String.isNullOrWhiteSpace(radioSelectlistValue)
                ? radioSelectlistValue
                    .split(template.parent.$systemSeparator)
                    .includes(item.key)
                : radioSelectlistValue == item.key
            "
            @change="radioSelectlistOnChange(item.key)"
            :disabled="disabled"
            :data-text="item.value"
          />
          <label class="btn btn-outline-success" :for="`${template.id}_${i}`"
            >{{ item.value }}
          </label>
        </template>
      </template>
      <template v-else>
        <div class="alert alert-warning mb-0 w-100">
          {{
            $t(
              "BaseModelFields.NoRecordFound",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "RadioSelectList",
  props: ["template", "items", "value", "valueId", "rowData", "disabled"],
  data() {
    return {
      prefix: this.template.field.isMultipleValue ? "checkbox" : "radio",
      radioSelectlistValue: null,
    };
  },
  computed: {
    currentSelf() {
      return this.template.child ? this.template.child : this.template.parent;
    },
  },
  mounted() {
    if (this.template.parent.isDxRowInlineEditing) {
      var selectListItem = this.template.parent.radioSelectListValues.find(
        (f) => f.fieldPublicId == this.template.field.publicId
      );
      if (selectListItem && selectListItem.editCellFieldValueChanges) {
        this.radioSelectlistValue = this.value;
      } else {
        this.radioSelectlistValue = this.valueId;
      }
    } else {
      this.radioSelectlistValue = this.valueId;
    }
  },
  methods: {
    focusedExternalButtons(e) {
      $(e.target).closest("td").find(".btn-group input:first").focus();
    },
    resetValue() {
      this.radioSelectlistValue = null;
      this.currentSelf.editCellTemplateValueChangedForRadioSelectListFieldTypes(
        this.template,
        null
      );
    },
    radioSelectlistOnChange(value) {
      if (this.template.field.isMultipleValue) {
        var checkedCheckboxes = $(`[name="${this.template.id}"]:checked`),
          checkedPublicIds = [];

        checkedCheckboxes.each(function (i, v) {
          checkedPublicIds.push($(v).val());
        });

        this.radioSelectlistValue = checkedPublicIds.join(
          this.template.parent.$systemSeparator
        );
      } else {
        this.radioSelectlistValue = value;
      }

      this.currentSelf.editCellTemplateValueChangedForRadioSelectListFieldTypes(
        this.template,
        this.radioSelectlistValue
      );
    },
  },
};
</script>
