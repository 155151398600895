<template>
  <div class="view-edit-mode" :class="{ hidden: isDetailPage }">
    <div class="sx-radio-select">
      <div
        class="btn-group btn-group-sm radio-select-val"
        :class="{
          'd-block': items.length > 2,
        }"
        :data-oldvalue="oldValue"
        :data-oldtext="oldText"
        role="group"
      >
        <template v-if="items.length > 0">
          <template v-for="(item, i) in items" :key="i">
            <input
              :type="prefix"
              class="btn-check"
              :name="field.publicId"
              :id="`${prefix}_${field.publicId}_${i}`"
              :value="item.key"
              :required="
                isRequired && String.isNullOrWhiteSpace(radioSelectlistValue)
              "
              :checked="
                field.isMultipleValue &&
                !String.isNullOrWhiteSpace(radioSelectlistValue)
                  ? radioSelectlistValue
                      .split(this.$systemSeparator)
                      .includes(item.key)
                  : radioSelectlistValue == item.key
              "
              @change="radioSelectlistOnChange(item.key)"
              :disabled="isDisabled"
            />
            <label
              class="btn btn-outline-success"
              :for="`${prefix}_${field.publicId}_${i}`"
              >{{ item.value }}
            </label>
          </template>
          <div class="invalid-tooltip">
            {{
              this.$t(
                "FieldWarnings.TextNotValid",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </template>
        <template v-else-if="!getSelectlistItemsRequestEnd">
          <div class="alert alert-warning mb-0 w-100">
            {{
              $t(
                "BaseModelFields.PleaseWait",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-warning mb-0 w-100">
            {{
              $t(
                "BaseModelFields.NoRecordFound",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <div
      class="d-flex text-break"
      :class="elementClasses"
      v-if="field.isMultipleValue && !String.isNullOrWhiteSpace(textsOrj)"
    >
      <span
        class="badge text-dark border border-primary me-1 fs-7"
        v-for="(item, i) in valuesOrj"
        :key="item"
      >
        {{ textsOrj[i] }}
      </span>
    </div>
    <span class="d-flex text-break" :class="elementClasses" v-else>{{
      values
    }}</span>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "RadioSelectList",
  props: [
    "element",
    "field",
    "value",
    "valueId",
    "crudType",
    "isCellEdit",
    "rowData",
  ],
  data() {
    return {
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      isRequired: this.field.isRequired || this.element.isRequired,
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      elementClasses: !String.isNullOrWhiteSpace(this.element.valueClassNames)
        ? this.element.valueClassNames
        : "",
      items: [],
      valuesOrj: [],
      textsOrj: [],
      values: "",
      oldValue: "",
      oldText: "",
      radioSelectlistValue: this.valueId,
      prefix: this.field.isMultipleValue ? "checkbox" : "radio",
      getSelectlistItemsRequestEnd: false,
    };
  },
  mounted() {
    if (!this.isDetailPage) {
      this.getSelectlistItems();
    }

    if (
      !String.isNullOrWhiteSpace(this.valueId) &&
      !String.isNullOrWhiteSpace(this.value)
    ) {
      if (Object.keys(this.valueId).length) {
        this.valuesOrj = this.valueId.split(this.$systemSeparator);
        this.textsOrj = this.value.split(this.$systemSeparator);
        this.oldValue = this.valueId;
        this.oldText = this.value;
      }
    }

    if (this.isDetailPage) {
      this.values = !String.isNullOrWhiteSpace(this.value)
        ? this.value.split(this.$systemSeparator).join(", ")
        : this.$valueNotAvailableSeparator;
    }
  },
  methods: {
    radioSelectlistOnChange(value) {
      if (this.field.isMultipleValue) {
        var checkedCheckboxes = $(`[name="${this.field.publicId}"]:checked`),
          allCheckboxes = $(`[name="${this.field.publicId}"]`),
          checkedPublicIds = [];

        checkedCheckboxes.each(function (i, v) {
          checkedPublicIds.push($(v).val());
        });

        this.radioSelectlistValue = checkedPublicIds.join(
          this.$systemSeparator
        );

        if (this.isRequired) {
          if (checkedPublicIds.length > 0) {
            allCheckboxes.removeAttr("required");
          } else {
            allCheckboxes.attr("required", "required");
          }
        }
      } else {
        this.radioSelectlistValue = value;
      }
      this.clearButtonShow();
    },
    clearButtonShow() {
      $(this.$parent.$refs.radioSelectlistClear).removeClass("hidden");
    },
    setValue(isSuccess, value, text) {
      this.radioSelectlistValue = value;

      if (isSuccess) {
        if (!String.isNullOrWhiteSpace(value)) {
          if (Object.keys(value).length) {
            this.valuesOrj = value.split(this.$systemSeparator);
            this.textsOrj = text.split(this.$systemSeparator);
          }
        }

        this.values = !String.isNullOrWhiteSpace(text)
          ? text.split(this.$systemSeparator).join(", ")
          : this.$valueNotAvailableSeparator;

        this.oldValue = value;
        this.oldText = text;
      } else {
        //close edit mode
        if (String.isNullOrWhiteSpace(this.radioSelectlistValue)) return;

        this.radioSelectlistValue
          .split(this.$systemSeparator)
          .forEach((element) => {
            $("[value=" + element + "]").prop("checked", true);
          });
      }
    },
    getSelectlistItems() {
      this.getSelectlistItemsRequestEnd = false;
      this.$appAxios
        .get(
          this.$root.getFieldRequestUrl("select", {
            id: this.field.publicId,
          })
        )
        .then((response) => {
          this.getSelectlistItemsRequestEnd = true;
          this.items = response.data;

          if (!this.items.length) {
            var clearBtn = document.getElementById(
              "sx-radio-select-clear-" + this.field.publicId
            );
            if (clearBtn) clearBtn.remove();
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
};
</script>
